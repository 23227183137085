import React, { useState, useEffect } from 'react';
import Axios from 'axios';
import { useNavigate } from 'react-router-dom';

function FullBiography() {

  // These are the variables that are set to pass the other biography data
  const [bigProfilePicture, setBigProfilePicture] = useState("");
  const [fullBiography, setFullBiography] = useState("");
  const navigate = useNavigate();

  const handleClick = () => {
    navigate("/");
  }


  function getOtherBiographyInformations(){

    Axios.defaults.withCredentials = true;

    Axios.get(`${process.env.REACT_APP_API}/api/biography`)
          .then((response) => {
            if (response.data) {
              setBigProfilePicture(response.data[0].bigprofilepicture);
              setFullBiography(response.data[0].fullbiography);
            }
            else{
                alert("Can't get the biography informations!");
            }
          })
          .catch((error) => {
              if (error.response) {
                alert("The server or client crashed to get the biography informations");
              }
              else if (error.request) {
                alert("Problem with the request");
              }
          });
  }

  useEffect(() => {
    getOtherBiographyInformations();
    // eslint-disable-next-line react-hooks/exhaustive-deps 
  }, []);

    return(
      
      <div id='fullbiographysection'>
        <img id='fullBiographyImage' src={`/images/${bigProfilePicture}`}/>
        <p id='fullBiographyParagraph'>{fullBiography}</p>
        
        <button id='buttonFullBiography' onClick={handleClick}>Go Back</button>

      </div>
    )
};

export default FullBiography;
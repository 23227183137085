import React from 'react';
import { Routes, Route} from "react-router-dom";
import Home from './Home';
import FullBiography from "./components/FullBiography/FullBiography";
import FullProject from "./components/FullProject/FullProject";

function App() {
    return (
        <Routes>
            <Route exact path="/" element={<Home/>}/>
            <Route exact path="/FullBiography" element={<FullBiography/>} />
            <Route path="/FullProject/:id" element={<FullProject/>}/>
        </Routes>
    );
}

export default App;


import React, { useState } from 'react';

const Header = () => {

  const [isNavExpanded, setIsNavExpanded] = useState(false);

  return (
    <nav className='navbar'>
      <div className='LogoSection'>
        <a href="#home">
          <img style={{ width: '100px', borderRadius: '25px' }} src="/images/logo.jpg"/>
        </a>
      </div>

      <button className='toggleButton' onClick={() => {setIsNavExpanded(!isNavExpanded)}}>
        {/* Code inside here could be used to add a logo to the hamburger button */}
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="h-5 w-5"
          viewBox="0 0 20 20"
          fill="white"
        >
          <path
            fillRule="evenodd"
            d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM9 15a1 1 0 011-1h6a1 1 0 110 2h-6a1 1 0 01-1-1z"
            clipRule="evenodd"
          />
        </svg>
      </button>


      <div className={isNavExpanded ? "navigation-menu expanded" : "navigation-menu"}>
        <ul>
          <li><a href="#about" onClick={() => {setIsNavExpanded(!isNavExpanded)}}>About</a></li>
          <li><a href="#projects" onClick={() => {setIsNavExpanded(!isNavExpanded)}}>Projects</a></li>        
          <li><a href="#tech" onClick={() => {setIsNavExpanded(!isNavExpanded)}}>Technologies</a></li>
          <li><a href="#contact" onClick={() => {setIsNavExpanded(!isNavExpanded)}}>Contact</a></li>   
        </ul>       
      </div>
    </nav>
  );
}


export default Header;

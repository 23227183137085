import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import Axios from 'axios';
import { useNavigate } from 'react-router-dom';

import "react-responsive-carousel/lib/styles/carousel.min.css";
import Carousel from 'react-bootstrap/Carousel';

function FullBiography() {


  let { id } = useParams();
  

  // this is to set all the local variables in the specific project
  const [fullDescription, setFullDescription] = useState("");
  const [imageList, setImageList] = useState([]);
  const [githublink, setGitHubLink] = useState("");
  const navigate = useNavigate();

  const handleClick = () => {
    navigate("/");
  }


  function getSpecificProjectInformations(){
    Axios.defaults.withCredentials = true;

      Axios.get(`${process.env.REACT_APP_API}/api/project/${id}`)
          .then((response) => {
            if (response.data) {
              setFullDescription(response.data.fulldescription);
              setImageList(response.data.imagelist);
              setGitHubLink(response.data.githublink);
            }
            else{
                alert("Can't get the full project informations!");
            }
          })
          .catch((error) => {
              if (error.response) {
                alert("The server or client crashed to get the full project informations");
              }
              else if (error.request) {
                alert("Problem with the request");
              }
          });
  }


  useEffect(() => {
    getSpecificProjectInformations();
    // eslint-disable-next-line react-hooks/exhaustive-deps 
  }, []);


  return(
    <div id='fullProjectSection'>

        <div id='fullProjectCarouselSection'>
          <Carousel width="100%" swipeable infiniteLoop autoPlay interval={5000} showStatus={false}>
            {imageList.map(image => {
              return(
                <Carousel.Item>
                  <img id='imgFullProjectSection' src={`/images/${image}`}/>
                </Carousel.Item>           
              );
            })}
          </Carousel>
        </div>
        <p id='paragraphFullProject'>{fullDescription}</p>
          
        <a id='codeSourceLinkGitHubFullProject' href={githublink}>See code source on GitHub</a>
        
        <button id='buttonGoMenuFullProject' onClick={handleClick}>Go to Home Menu</button>
    </div>
  )
};

export default FullBiography;
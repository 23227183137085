import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Axios from 'axios';


const Biography = ({getButtonState, getFullName}) => { 

    //These are the variables that are set to pass the biography data in the entire applications

    const [biographyId, setBiographyId] = useState("");
    const [profilePicture, setProfilePicture] = useState("");
    const [smallIntroduction, setSmallIntroduction] = useState("");

    // This is to handle the navigation on the other page
    const navigate = useNavigate();

    const handleClick = () => {
      navigate("/FullBiography");
    }

    // This will display the biography informations and update the information in the entire website 

    function getBiographyInformations() {

      Axios.defaults.withCredentials = true;

      Axios.get(`${process.env.REACT_APP_API}/api/biography`)
          .then((response) => {
            if (response.data) {
                setBiographyId(response.data[0]._id);
                getFullName(response.data[0].name);
                setProfilePicture(response.data[0].smallprofilepicture);
                setSmallIntroduction(response.data[0].smallintro);
            }
            else{
                alert("Can't get the biography informations!");
            }
          })
          .catch((error) => {
              if (error.response) {
                alert("The server or client crashed to get the biography informations");
              }
              else if (error.request) {
                alert("Problem with the request");
              }
          });
    }

    useEffect(() => {
        getBiographyInformations();
        // eslint-disable-next-line react-hooks/exhaustive-deps 
    }, []);



    return (
    <>
      <section row nopadding id="about">
        
        <div id="leftSectionBiography">
          <h2 id="biographyTitle" main>About Me</h2>
          <img id='imgBiography' src={`/images/${profilePicture}`}/>
          <p className='sectionTextBiography'>
              {smallIntroduction}
          </p>
          <p className='sectionTextBiography'>Click the button below to learn more about me!</p>
          <div>
            <button id="normalButtonBiography" onClick={ handleClick }>Learn More</button>
          </div>
          <div style={{ width:"100%", textAlign: "center"}}>
            <a id='downloadButtonBiography' href="/resume/CV Nicolas Espitalier (English).pdf" target='_blank' download>Download Resume</a>
          </div>
        </div>
      </section>
    </>
  )
};

export default Biography;
import React from 'react';

const Video = ({ name }) => {

  return (
    <div id='home'>

      <video id="backgroundVideo" loop muted autoPlay src='/video/videoBackground.mp4' />

      <div id='textGroup'>
        <p id='firstHeader'>Welcome visitor!</p>
        <p id='secondHeader'>My name is</p>
        <h2 id='thirdHeader'>{name}</h2>
      </div>
    </div>
  );
};

export default Video;
import React from 'react';

class BackgroundAnimation extends React.Component {
  render(){
    return(
      <div style={{ marginTop: "40%", marginLeft: "20%"}}>
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 3000 3000">
          <g id="Cubes">
            <g id="CubeGroup1">
              <g id="Cube">
                <polygon points="1291.84 64.46 1186.76 125.12 1291.84 185.79 1396.92 125.12 1291.84 64.46" fill="#2d3359" stroke="#57f2c6" stroke-miterlimit="10" stroke-width="4"></polygon>
                <polygon points="1186.76 125.12 1186.76 246.46 1291.84 307.13 1291.84 185.79 1186.76 125.12" fill="#2d3359" stroke="#57f2c6" stroke-miterlimit="10" stroke-width="4"></polygon>
                <polygon points="1291.84 307.13 1396.92 246.46 1396.92 125.12 1291.84 185.79 1291.84 307.13" fill="#2d3359" stroke="#57f2c6" stroke-miterlimit="10" stroke-width="4"></polygon>
              </g>
              <g id="Cube-2" data-name="Cube">
                <polygon points="1186.84 125.46 1081.76 186.12 1186.84 246.79 1291.92 186.12 1186.84 125.46" fill="#2d3359" stroke="#57f2c6" stroke-miterlimit="10" stroke-width="4"></polygon>
                <polygon points="1081.76 186.12 1081.76 307.46 1186.84 368.13 1186.84 246.79 1081.76 186.12" fill="#2d3359" stroke="#57f2c6" stroke-miterlimit="10" stroke-width="4"></polygon>
                <polygon points="1186.84 368.13 1291.92 307.46 1291.92 186.12 1186.84 246.79 1186.84 368.13" fill="#2d3359" stroke="#57f2c6" stroke-miterlimit="10" stroke-width="4"></polygon>
              </g>
              <g id="Cube-3" data-name="Cube">
                <polygon points="1081.84 186.46 976.76 247.12 1081.84 307.79 1186.92 247.12 1081.84 186.46" fill="#2d3359" stroke="#57f2c6" stroke-miterlimit="10" stroke-width="4"></polygon>
                <polygon points="976.76 247.12 976.76 368.46 1081.84 429.13 1081.84 307.79 976.76 247.12" fill="#2d3359" stroke="#57f2c6" stroke-miterlimit="10" stroke-width="4"></polygon>
                <polygon points="1081.84 429.13 1186.92 368.46 1186.92 247.12 1081.84 307.79 1081.84 429.13" fill="#2d3359" stroke="#57f2c6" stroke-miterlimit="10" stroke-width="4"></polygon>
              </g>
              <g id="Cube-4" data-name="Cube">
                <polygon points="976.84 247.46 871.76 308.12 976.84 368.79 1081.92 308.12 976.84 247.46" fill="#2d3359" stroke="#57f2c6" stroke-miterlimit="10" stroke-width="4"></polygon>
                <polygon points="871.76 308.12 871.76 429.46 976.84 490.13 976.84 368.79 871.76 308.12" fill="#2d3359" stroke="#57f2c6" stroke-miterlimit="10" stroke-width="4"></polygon>
                <polygon points="976.84 490.13 1081.92 429.46 1081.92 308.12 976.84 368.79 976.84 490.13" fill="#2d3359" stroke="#57f2c6" stroke-miterlimit="10" stroke-width="4"></polygon>
              </g>
              <g id="Cube-5" data-name="Cube">
                <polygon points="871.84 308.46 766.76 369.12 871.84 429.79 976.92 369.12 871.84 308.46" fill="#2d3359" stroke="#57f2c6" stroke-miterlimit="10" stroke-width="4"></polygon>
                <polygon points="766.76 369.12 766.76 490.46 871.84 551.13 871.84 429.79 766.76 369.12" fill="#2d3359" stroke="#57f2c6" stroke-miterlimit="10" stroke-width="4"></polygon>
                <polygon points="871.84 551.13 976.92 490.46 976.92 369.12 871.84 429.79 871.84 551.13" fill="#2d3359" stroke="#57f2c6" stroke-miterlimit="10" stroke-width="4"></polygon>
              </g>
              <g id="Cube-6" data-name="Cube">
                <polygon points="766.84 369.46 661.76 430.12 766.84 490.79 871.92 430.12 766.84 369.46" fill="#2d3359" stroke="#57f2c6" stroke-miterlimit="10" stroke-width="4"></polygon>
                <polygon points="661.76 430.12 661.76 551.46 766.84 612.13 766.84 490.79 661.76 430.12" fill="#2d3359" stroke="#57f2c6" stroke-miterlimit="10" stroke-width="4"></polygon>
                <polygon points="766.84 612.13 871.92 551.46 871.92 430.12 766.84 490.79 766.84 612.13" fill="#2d3359" stroke="#57f2c6" stroke-miterlimit="10" stroke-width="4"></polygon>
              </g>
              <g id="Cube-7" data-name="Cube">
                <polygon points="661.84 430.46 556.76 491.12 661.84 551.79 766.92 491.12 661.84 430.46" fill="#2d3359" stroke="#57f2c6" stroke-miterlimit="10" stroke-width="4"></polygon>
                <polygon points="556.76 491.12 556.76 612.46 661.84 673.13 661.84 551.79 556.76 491.12" fill="#2d3359" stroke="#57f2c6" stroke-miterlimit="10" stroke-width="4"></polygon>
                <polygon points="661.84 673.13 766.92 612.46 766.92 491.12 661.84 551.79 661.84 673.13" fill="#2d3359" stroke="#57f2c6" stroke-miterlimit="10" stroke-width="4"></polygon>
              </g>
              <g id="Cube-8" data-name="Cube">
                <polygon points="556.84 491.46 451.76 552.12 556.84 612.79 661.92 552.12 556.84 491.46" fill="#2d3359" stroke="#57f2c6" stroke-miterlimit="10" stroke-width="4"></polygon>
                <polygon points="451.76 552.12 451.76 673.46 556.84 734.13 556.84 612.79 451.76 552.12" fill="#2d3359" stroke="#57f2c6" stroke-miterlimit="10" stroke-width="4"></polygon>
                <polygon points="556.84 734.13 661.92 673.46 661.92 552.12 556.84 612.79 556.84 734.13" fill="#2d3359" stroke="#57f2c6" stroke-miterlimit="10" stroke-width="4"></polygon>
              </g>
              <g id="Cube-9" data-name="Cube">
                <polygon points="451.84 552.46 346.76 613.12 451.84 673.79 556.92 613.12 451.84 552.46" fill="#2d3359" stroke="#57f2c6" stroke-miterlimit="10" stroke-width="4"></polygon>
                <polygon points="346.76 613.12 346.76 734.46 451.84 795.13 451.84 673.79 346.76 613.12" fill="#2d3359" stroke="#57f2c6" stroke-miterlimit="10" stroke-width="4"></polygon>
                <polygon points="451.84 795.13 556.92 734.46 556.92 613.12 451.84 673.79 451.84 795.13" fill="#2d3359" stroke="#57f2c6" stroke-miterlimit="10" stroke-width="4"></polygon>
              </g>
              <g id="Cube-10" data-name="Cube">
                <polygon points="346.84 613.46 241.76 674.12 346.84 734.79 451.92 674.12 346.84 613.46" fill="#2d3359" stroke="#57f2c6" stroke-miterlimit="10" stroke-width="4"></polygon>
                <polygon points="241.76 674.12 241.76 795.46 346.84 856.13 346.84 734.79 241.76 674.12" fill="#2d3359" stroke="#57f2c6" stroke-miterlimit="10" stroke-width="4"></polygon>
                <polygon points="346.84 856.13 451.92 795.46 451.92 674.12 346.84 734.79 346.84 856.13" fill="#2d3359" stroke="#57f2c6" stroke-miterlimit="10" stroke-width="4"></polygon>
              </g>
              <g id="Cube-11" data-name="Cube">
                <polygon points="241.84 674.46 136.76 735.12 241.84 795.79 346.92 735.12 241.84 674.46" fill="#2d3359" stroke="#57f2c6" stroke-miterlimit="10" stroke-width="4"></polygon>
                <polygon points="136.76 735.12 136.76 856.46 241.84 917.13 241.84 795.79 136.76 735.12" fill="#2d3359" stroke="#57f2c6" stroke-miterlimit="10" stroke-width="4"></polygon>
                <polygon points="241.84 917.13 346.92 856.46 346.92 735.12 241.84 795.79 241.84 917.13" fill="#2d3359" stroke="#57f2c6" stroke-miterlimit="10" stroke-width="4"></polygon>
              </g>
              <g id="Cube-12" data-name="Cube">
                <polygon points="136.84 735.46 31.76 796.12 136.84 856.79 241.92 796.12 136.84 735.46" fill="#2d3359" stroke="#57f2c6" stroke-miterlimit="10" stroke-width="4"></polygon>
                <polygon points="31.76 796.12 31.76 917.46 136.84 978.13 136.84 856.79 31.76 796.12" fill="#2d3359" stroke="#57f2c6" stroke-miterlimit="10" stroke-width="4"></polygon>
                <polygon points="136.84 978.13 241.92 917.46 241.92 796.12 136.84 856.79 136.84 978.13" fill="#2d3359" stroke="#57f2c6" stroke-miterlimit="10" stroke-width="4"></polygon>
              </g>
            </g>
            <g id="CubeGroup2">
              <g id="Cube-13" data-name="Cube">
                <polygon points="1396.84 125.46 1291.76 186.12 1396.84 246.79 1501.92 186.12 1396.84 125.46" fill="#2d3359" stroke="#57f2c6" stroke-miterlimit="10" stroke-width="4"></polygon>
                <polygon points="1291.76 186.12 1291.76 307.46 1396.84 368.13 1396.84 246.79 1291.76 186.12" fill="#2d3359" stroke="#57f2c6" stroke-miterlimit="10" stroke-width="4"></polygon>
                <polygon points="1396.84 368.13 1501.92 307.46 1501.92 186.12 1396.84 246.79 1396.84 368.13" fill="#2d3359" stroke="#57f2c6" stroke-miterlimit="10" stroke-width="4"></polygon>
              </g>
              <g id="Cube-14" data-name="Cube">
                <polygon points="1291.84 186.46 1186.76 247.12 1291.84 307.79 1396.92 247.12 1291.84 186.46" fill="#2d3359" stroke="#57f2c6" stroke-miterlimit="10" stroke-width="4"></polygon>
                <polygon points="1186.76 247.12 1186.76 368.46 1291.84 429.13 1291.84 307.79 1186.76 247.12" fill="#2d3359" stroke="#57f2c6" stroke-miterlimit="10" stroke-width="4"></polygon>
                <polygon points="1291.84 429.13 1396.92 368.46 1396.92 247.12 1291.84 307.79 1291.84 429.13" fill="#2d3359" stroke="#57f2c6" stroke-miterlimit="10" stroke-width="4"></polygon>
              </g>
              <g id="Cube-15" data-name="Cube">
                <polygon points="1186.84 247.46 1081.76 308.12 1186.84 368.79 1291.92 308.12 1186.84 247.46" fill="#2d3359" stroke="#57f2c6" stroke-miterlimit="10" stroke-width="4"></polygon>
                <polygon points="1081.76 308.12 1081.76 429.46 1186.84 490.13 1186.84 368.79 1081.76 308.12" fill="#2d3359" stroke="#57f2c6" stroke-miterlimit="10" stroke-width="4"></polygon>
                <polygon points="1186.84 490.13 1291.92 429.46 1291.92 308.12 1186.84 368.79 1186.84 490.13" fill="#2d3359" stroke="#57f2c6" stroke-miterlimit="10" stroke-width="4"></polygon>
              </g>
              <g id="Cube-16" data-name="Cube">
                <polygon points="1081.84 308.46 976.76 369.12 1081.84 429.79 1186.92 369.12 1081.84 308.46" fill="#2d3359" stroke="#57f2c6" stroke-miterlimit="10" stroke-width="4"></polygon>
                <polygon points="976.76 369.12 976.76 490.46 1081.84 551.13 1081.84 429.79 976.76 369.12" fill="#2d3359" stroke="#57f2c6" stroke-miterlimit="10" stroke-width="4"></polygon>
                <polygon points="1081.84 551.13 1186.92 490.46 1186.92 369.12 1081.84 429.79 1081.84 551.13" fill="#2d3359" stroke="#57f2c6" stroke-miterlimit="10" stroke-width="4"></polygon>
              </g>
              <g id="Cube-17" data-name="Cube">
                <polygon points="976.84 369.46 871.76 430.12 976.84 490.79 1081.92 430.12 976.84 369.46" fill="#2d3359" stroke="#57f2c6" stroke-miterlimit="10" stroke-width="4"></polygon>
                <polygon points="871.76 430.12 871.76 551.46 976.84 612.13 976.84 490.79 871.76 430.12" fill="#2d3359" stroke="#57f2c6" stroke-miterlimit="10" stroke-width="4"></polygon>
                <polygon points="976.84 612.13 1081.92 551.46 1081.92 430.12 976.84 490.79 976.84 612.13" fill="#2d3359" stroke="#57f2c6" stroke-miterlimit="10" stroke-width="4"></polygon>
              </g>
              <g id="Cube-18" data-name="Cube">
                <polygon points="871.84 430.46 766.76 491.12 871.84 551.79 976.92 491.12 871.84 430.46" fill="#2d3359" stroke="#57f2c6" stroke-miterlimit="10" stroke-width="4"></polygon>
                <polygon points="766.76 491.12 766.76 612.46 871.84 673.13 871.84 551.79 766.76 491.12" fill="#2d3359" stroke="#57f2c6" stroke-miterlimit="10" stroke-width="4"></polygon>
                <polygon points="871.84 673.13 976.92 612.46 976.92 491.12 871.84 551.79 871.84 673.13" fill="#2d3359" stroke="#57f2c6" stroke-miterlimit="10" stroke-width="4"></polygon>
              </g>
              <g id="Cube-19" data-name="Cube">
                <polygon points="766.84 491.46 661.76 552.12 766.84 612.79 871.92 552.12 766.84 491.46" fill="#2d3359" stroke="#57f2c6" stroke-miterlimit="10" stroke-width="4"></polygon>
                <polygon points="661.76 552.12 661.76 673.46 766.84 734.13 766.84 612.79 661.76 552.12" fill="#2d3359" stroke="#57f2c6" stroke-miterlimit="10" stroke-width="4"></polygon>
                <polygon points="766.84 734.13 871.92 673.46 871.92 552.12 766.84 612.79 766.84 734.13" fill="#2d3359" stroke="#57f2c6" stroke-miterlimit="10" stroke-width="4"></polygon>
              </g>
              <g id="Cube-20" data-name="Cube">
                <polygon points="661.84 552.46 556.76 613.12 661.84 673.79 766.92 613.12 661.84 552.46" fill="#2d3359" stroke="#57f2c6" stroke-miterlimit="10" stroke-width="4"></polygon>
                <polygon points="556.76 613.12 556.76 734.46 661.84 795.13 661.84 673.79 556.76 613.12" fill="#2d3359" stroke="#57f2c6" stroke-miterlimit="10" stroke-width="4"></polygon>
                <polygon points="661.84 795.13 766.92 734.46 766.92 613.12 661.84 673.79 661.84 795.13" fill="#2d3359" stroke="#57f2c6" stroke-miterlimit="10" stroke-width="4"></polygon>
              </g>
              <g id="Cube-21" data-name="Cube">
                <polygon points="556.84 613.46 451.76 674.12 556.84 734.79 661.92 674.12 556.84 613.46" fill="#2d3359" stroke="#57f2c6" stroke-miterlimit="10" stroke-width="4"></polygon>
                <polygon points="451.76 674.12 451.76 795.46 556.84 856.13 556.84 734.79 451.76 674.12" fill="#2d3359" stroke="#57f2c6" stroke-miterlimit="10" stroke-width="4"></polygon>
                <polygon points="556.84 856.13 661.92 795.46 661.92 674.12 556.84 734.79 556.84 856.13" fill="#2d3359" stroke="#57f2c6" stroke-miterlimit="10" stroke-width="4"></polygon>
              </g>
              <g id="Cube-22" data-name="Cube">
                <polygon points="451.84 674.46 346.76 735.12 451.84 795.79 556.92 735.12 451.84 674.46" fill="#2d3359" stroke="#57f2c6" stroke-miterlimit="10" stroke-width="4"></polygon>
                <polygon points="346.76 735.12 346.76 856.46 451.84 917.13 451.84 795.79 346.76 735.12" fill="#2d3359" stroke="#57f2c6" stroke-miterlimit="10" stroke-width="4"></polygon>
                <polygon points="451.84 917.13 556.92 856.46 556.92 735.12 451.84 795.79 451.84 917.13" fill="#2d3359" stroke="#57f2c6" stroke-miterlimit="10" stroke-width="4"></polygon>
              </g>
              <g id="Cube-23" data-name="Cube">
                <polygon points="346.84 735.46 241.76 796.12 346.84 856.79 451.92 796.12 346.84 735.46" fill="#2d3359" stroke="#57f2c6" stroke-miterlimit="10" stroke-width="4"></polygon>
                <polygon points="241.76 796.12 241.76 917.46 346.84 978.13 346.84 856.79 241.76 796.12" fill="#2d3359" stroke="#57f2c6" stroke-miterlimit="10" stroke-width="4"></polygon>
                <polygon points="346.84 978.13 451.92 917.46 451.92 796.12 346.84 856.79 346.84 978.13" fill="#2d3359" stroke="#57f2c6" stroke-miterlimit="10" stroke-width="4"></polygon>
              </g>
              <g id="Cube-24" data-name="Cube">
                <polygon points="241.84 796.46 136.76 857.12 241.84 917.79 346.92 857.12 241.84 796.46" fill="#2d3359" stroke="#57f2c6" stroke-miterlimit="10" stroke-width="4"></polygon>
                <polygon points="136.76 857.12 136.76 978.46 241.84 1039.13 241.84 917.79 136.76 857.12" fill="#2d3359" stroke="#57f2c6" stroke-miterlimit="10" stroke-width="4"></polygon>
                <polygon points="241.84 1039.13 346.92 978.46 346.92 857.12 241.84 917.79 241.84 1039.13" fill="#2d3359" stroke="#57f2c6" stroke-miterlimit="10" stroke-width="4"></polygon>
              </g>
            </g>
            <g id="CubeGroup3">
              <g id="Cube-25" data-name="Cube">
                <polygon points="1501.84 186.46 1396.76 247.12 1501.84 307.79 1606.92 247.12 1501.84 186.46" fill="#2d3359" stroke="#57f2c6" stroke-miterlimit="10" stroke-width="4"></polygon>
                <polygon points="1396.76 247.12 1396.76 368.46 1501.84 429.13 1501.84 307.79 1396.76 247.12" fill="#2d3359" stroke="#57f2c6" stroke-miterlimit="10" stroke-width="4"></polygon>
                <polygon points="1501.84 429.13 1606.92 368.46 1606.92 247.12 1501.84 307.79 1501.84 429.13" fill="#2d3359" stroke="#57f2c6" stroke-miterlimit="10" stroke-width="4"></polygon>
              </g>
              <g id="Cube-26" data-name="Cube">
                <polygon points="1396.84 247.46 1291.76 308.12 1396.84 368.79 1501.92 308.12 1396.84 247.46" fill="#2d3359" stroke="#57f2c6" stroke-miterlimit="10" stroke-width="4"></polygon>
                <polygon points="1291.76 308.12 1291.76 429.46 1396.84 490.13 1396.84 368.79 1291.76 308.12" fill="#2d3359" stroke="#57f2c6" stroke-miterlimit="10" stroke-width="4"></polygon>
                <polygon points="1396.84 490.13 1501.92 429.46 1501.92 308.12 1396.84 368.79 1396.84 490.13" fill="#2d3359" stroke="#57f2c6" stroke-miterlimit="10" stroke-width="4"></polygon>
              </g>
              <g id="Cube-27" data-name="Cube">
                <polygon points="1291.84 308.46 1186.76 369.12 1291.84 429.79 1396.92 369.12 1291.84 308.46" fill="#2d3359" stroke="#57f2c6" stroke-miterlimit="10" stroke-width="4"></polygon>
                <polygon points="1186.76 369.12 1186.76 490.46 1291.84 551.13 1291.84 429.79 1186.76 369.12" fill="#2d3359" stroke="#57f2c6" stroke-miterlimit="10" stroke-width="4"></polygon>
                <polygon points="1291.84 551.13 1396.92 490.46 1396.92 369.12 1291.84 429.79 1291.84 551.13" fill="#2d3359" stroke="#57f2c6" stroke-miterlimit="10" stroke-width="4"></polygon>
              </g>
              <g id="Cube-28" data-name="Cube">
                <polygon points="1186.84 369.46 1081.76 430.12 1186.84 490.79 1291.92 430.12 1186.84 369.46" fill="#2d3359" stroke="#57f2c6" stroke-miterlimit="10" stroke-width="4"></polygon>
                <polygon points="1081.76 430.12 1081.76 551.46 1186.84 612.13 1186.84 490.79 1081.76 430.12" fill="#2d3359" stroke="#57f2c6" stroke-miterlimit="10" stroke-width="4"></polygon>
                <polygon points="1186.84 612.13 1291.92 551.46 1291.92 430.12 1186.84 490.79 1186.84 612.13" fill="#2d3359" stroke="#57f2c6" stroke-miterlimit="10" stroke-width="4"></polygon>
              </g>
              <g id="Cube-29" data-name="Cube">
                <polygon points="1081.84 430.46 976.76 491.12 1081.84 551.79 1186.92 491.12 1081.84 430.46" fill="#2d3359" stroke="#57f2c6" stroke-miterlimit="10" stroke-width="4"></polygon>
                <polygon points="976.76 491.12 976.76 612.46 1081.84 673.13 1081.84 551.79 976.76 491.12" fill="#2d3359" stroke="#57f2c6" stroke-miterlimit="10" stroke-width="4"></polygon>
                <polygon points="1081.84 673.13 1186.92 612.46 1186.92 491.12 1081.84 551.79 1081.84 673.13" fill="#2d3359" stroke="#57f2c6" stroke-miterlimit="10" stroke-width="4"></polygon>
              </g>
              <g id="Cube-30" data-name="Cube">
                <polygon points="976.84 491.46 871.76 552.12 976.84 612.79 1081.92 552.12 976.84 491.46" fill="#2d3359" stroke="#57f2c6" stroke-miterlimit="10" stroke-width="4"></polygon>
                <polygon points="871.76 552.12 871.76 673.46 976.84 734.13 976.84 612.79 871.76 552.12" fill="#2d3359" stroke="#57f2c6" stroke-miterlimit="10" stroke-width="4"></polygon>
                <polygon points="976.84 734.13 1081.92 673.46 1081.92 552.12 976.84 612.79 976.84 734.13" fill="#2d3359" stroke="#57f2c6" stroke-miterlimit="10" stroke-width="4"></polygon>
              </g>
              <g id="Cube-31" data-name="Cube">
                <polygon points="871.84 552.46 766.76 613.12 871.84 673.79 976.92 613.12 871.84 552.46" fill="#2d3359" stroke="#57f2c6" stroke-miterlimit="10" stroke-width="4"></polygon>
                <polygon points="766.76 613.12 766.76 734.46 871.84 795.13 871.84 673.79 766.76 613.12" fill="#2d3359" stroke="#57f2c6" stroke-miterlimit="10" stroke-width="4"></polygon>
                <polygon points="871.84 795.13 976.92 734.46 976.92 613.12 871.84 673.79 871.84 795.13" fill="#2d3359" stroke="#57f2c6" stroke-miterlimit="10" stroke-width="4"></polygon>
              </g>
              <g id="Cube-32" data-name="Cube">
                <polygon points="766.84 613.46 661.76 674.12 766.84 734.79 871.92 674.12 766.84 613.46" fill="#2d3359" stroke="#57f2c6" stroke-miterlimit="10" stroke-width="4"></polygon>
                <polygon points="661.76 674.12 661.76 795.46 766.84 856.13 766.84 734.79 661.76 674.12" fill="#2d3359" stroke="#57f2c6" stroke-miterlimit="10" stroke-width="4"></polygon>
                <polygon points="766.84 856.13 871.92 795.46 871.92 674.12 766.84 734.79 766.84 856.13" fill="#2d3359" stroke="#57f2c6" stroke-miterlimit="10" stroke-width="4"></polygon>
              </g>
              <g id="Cube-33" data-name="Cube">
                <polygon points="661.84 674.46 556.76 735.12 661.84 795.79 766.92 735.12 661.84 674.46" fill="#2d3359" stroke="#57f2c6" stroke-miterlimit="10" stroke-width="4"></polygon>
                <polygon points="556.76 735.12 556.76 856.46 661.84 917.13 661.84 795.79 556.76 735.12" fill="#2d3359" stroke="#57f2c6" stroke-miterlimit="10" stroke-width="4"></polygon>
                <polygon points="661.84 917.13 766.92 856.46 766.92 735.12 661.84 795.79 661.84 917.13" fill="#2d3359" stroke="#57f2c6" stroke-miterlimit="10" stroke-width="4"></polygon>
              </g>
              <g id="Cube-34" data-name="Cube">
                <polygon points="556.84 735.46 451.76 796.12 556.84 856.79 661.92 796.12 556.84 735.46" fill="#2d3359" stroke="#57f2c6" stroke-miterlimit="10" stroke-width="4"></polygon>
                <polygon points="451.76 796.12 451.76 917.46 556.84 978.13 556.84 856.79 451.76 796.12" fill="#2d3359" stroke="#57f2c6" stroke-miterlimit="10" stroke-width="4"></polygon>
                <polygon points="556.84 978.13 661.92 917.46 661.92 796.12 556.84 856.79 556.84 978.13" fill="#2d3359" stroke="#57f2c6" stroke-miterlimit="10" stroke-width="4"></polygon>
              </g>
              <g id="Cube-35" data-name="Cube">
                <polygon points="451.84 796.46 346.76 857.12 451.84 917.79 556.92 857.12 451.84 796.46" fill="#2d3359" stroke="#57f2c6" stroke-miterlimit="10" stroke-width="4"></polygon>
                <polygon points="346.76 857.12 346.76 978.46 451.84 1039.13 451.84 917.79 346.76 857.12" fill="#2d3359" stroke="#57f2c6" stroke-miterlimit="10" stroke-width="4"></polygon>
                <polygon points="451.84 1039.13 556.92 978.46 556.92 857.12 451.84 917.79 451.84 1039.13" fill="#2d3359" stroke="#57f2c6" stroke-miterlimit="10" stroke-width="4"></polygon>
              </g>
              <g id="Cube-36" data-name="Cube">
                <polygon points="346.84 857.46 241.76 918.12 346.84 978.79 451.92 918.12 346.84 857.46" fill="#2d3359" stroke="#57f2c6" stroke-miterlimit="10" stroke-width="4"></polygon>
                <polygon points="241.76 918.12 241.76 1039.46 346.84 1100.13 346.84 978.79 241.76 918.12" fill="#2d3359" stroke="#57f2c6" stroke-miterlimit="10" stroke-width="4"></polygon>
                <polygon points="346.84 1100.13 451.92 1039.46 451.92 918.12 346.84 978.79 346.84 1100.13" fill="#2d3359" stroke="#57f2c6" stroke-miterlimit="10" stroke-width="4"></polygon>
              </g>
            </g>
            <g id="CubeGroup4">
              <g id="Cube-37" data-name="Cube">
                <polygon points="1606.84 247.46 1501.76 308.12 1606.84 368.79 1711.92 308.12 1606.84 247.46" fill="#2d3359" stroke="#57f2c6" stroke-miterlimit="10" stroke-width="4"></polygon>
                <polygon points="1501.76 308.12 1501.76 429.46 1606.84 490.13 1606.84 368.79 1501.76 308.12" fill="#2d3359" stroke="#57f2c6" stroke-miterlimit="10" stroke-width="4"></polygon>
                <polygon points="1606.84 490.13 1711.92 429.46 1711.92 308.12 1606.84 368.79 1606.84 490.13" fill="#2d3359" stroke="#57f2c6" stroke-miterlimit="10" stroke-width="4"></polygon>
              </g>
              <g id="Cube-38" data-name="Cube">
                <polygon points="1501.84 308.46 1396.76 369.12 1501.84 429.79 1606.92 369.12 1501.84 308.46" fill="#2d3359" stroke="#57f2c6" stroke-miterlimit="10" stroke-width="4"></polygon>
                <polygon points="1396.76 369.12 1396.76 490.46 1501.84 551.13 1501.84 429.79 1396.76 369.12" fill="#2d3359" stroke="#57f2c6" stroke-miterlimit="10" stroke-width="4"></polygon>
                <polygon points="1501.84 551.13 1606.92 490.46 1606.92 369.12 1501.84 429.79 1501.84 551.13" fill="#2d3359" stroke="#57f2c6" stroke-miterlimit="10" stroke-width="4"></polygon>
              </g>
              <g id="Cube-39" data-name="Cube">
                <polygon points="1396.84 369.46 1291.76 430.12 1396.84 490.79 1501.92 430.12 1396.84 369.46" fill="#2d3359" stroke="#57f2c6" stroke-miterlimit="10" stroke-width="4"></polygon>
                <polygon points="1291.76 430.12 1291.76 551.46 1396.84 612.13 1396.84 490.79 1291.76 430.12" fill="#2d3359" stroke="#57f2c6" stroke-miterlimit="10" stroke-width="4"></polygon>
                <polygon points="1396.84 612.13 1501.92 551.46 1501.92 430.12 1396.84 490.79 1396.84 612.13" fill="#2d3359" stroke="#57f2c6" stroke-miterlimit="10" stroke-width="4"></polygon>
              </g>
              <g id="Cube-40" data-name="Cube">
                <polygon points="1291.84 430.46 1186.76 491.12 1291.84 551.79 1396.92 491.12 1291.84 430.46" fill="#2d3359" stroke="#57f2c6" stroke-miterlimit="10" stroke-width="4"></polygon>
                <polygon points="1186.76 491.12 1186.76 612.46 1291.84 673.13 1291.84 551.79 1186.76 491.12" fill="#2d3359" stroke="#57f2c6" stroke-miterlimit="10" stroke-width="4"></polygon>
                <polygon points="1291.84 673.13 1396.92 612.46 1396.92 491.12 1291.84 551.79 1291.84 673.13" fill="#2d3359" stroke="#57f2c6" stroke-miterlimit="10" stroke-width="4"></polygon>
              </g>
              <g id="Cube-41" data-name="Cube">
                <polygon points="1186.84 491.46 1081.76 552.12 1186.84 612.79 1291.92 552.12 1186.84 491.46" fill="#2d3359" stroke="#57f2c6" stroke-miterlimit="10" stroke-width="4"></polygon>
                <polygon points="1081.76 552.12 1081.76 673.46 1186.84 734.13 1186.84 612.79 1081.76 552.12" fill="#2d3359" stroke="#57f2c6" stroke-miterlimit="10" stroke-width="4"></polygon>
                <polygon points="1186.84 734.13 1291.92 673.46 1291.92 552.12 1186.84 612.79 1186.84 734.13" fill="#2d3359" stroke="#57f2c6" stroke-miterlimit="10" stroke-width="4"></polygon>
              </g>
              <g id="Cube-42" data-name="Cube">
                <polygon points="1081.84 552.46 976.76 613.12 1081.84 673.79 1186.92 613.12 1081.84 552.46" fill="#2d3359" stroke="#57f2c6" stroke-miterlimit="10" stroke-width="4"></polygon>
                <polygon points="976.76 613.12 976.76 734.46 1081.84 795.13 1081.84 673.79 976.76 613.12" fill="#2d3359" stroke="#57f2c6" stroke-miterlimit="10" stroke-width="4"></polygon>
                <polygon points="1081.84 795.13 1186.92 734.46 1186.92 613.12 1081.84 673.79 1081.84 795.13" fill="#2d3359" stroke="#57f2c6" stroke-miterlimit="10" stroke-width="4"></polygon>
              </g>
              <g id="Cube-43" data-name="Cube">
                <polygon points="976.84 613.46 871.76 674.12 976.84 734.79 1081.92 674.12 976.84 613.46" fill="#2d3359" stroke="#57f2c6" stroke-miterlimit="10" stroke-width="4"></polygon>
                <polygon points="871.76 674.12 871.76 795.46 976.84 856.13 976.84 734.79 871.76 674.12" fill="#2d3359" stroke="#57f2c6" stroke-miterlimit="10" stroke-width="4"></polygon>
                <polygon points="976.84 856.13 1081.92 795.46 1081.92 674.12 976.84 734.79 976.84 856.13" fill="#2d3359" stroke="#57f2c6" stroke-miterlimit="10" stroke-width="4"></polygon>
              </g>
              <g id="Cube-44" data-name="Cube">
                <polygon points="871.84 674.46 766.76 735.12 871.84 795.79 976.92 735.12 871.84 674.46" fill="#2d3359" stroke="#57f2c6" stroke-miterlimit="10" stroke-width="4"></polygon>
                <polygon points="766.76 735.12 766.76 856.46 871.84 917.13 871.84 795.79 766.76 735.12" fill="#2d3359" stroke="#57f2c6" stroke-miterlimit="10" stroke-width="4"></polygon>
                <polygon points="871.84 917.13 976.92 856.46 976.92 735.12 871.84 795.79 871.84 917.13" fill="#2d3359" stroke="#57f2c6" stroke-miterlimit="10" stroke-width="4"></polygon>
              </g>
              <g id="Cube-45" data-name="Cube">
                <polygon points="766.84 735.46 661.76 796.12 766.84 856.79 871.92 796.12 766.84 735.46" fill="#2d3359" stroke="#57f2c6" stroke-miterlimit="10" stroke-width="4"></polygon>
                <polygon points="661.76 796.12 661.76 917.46 766.84 978.13 766.84 856.79 661.76 796.12" fill="#2d3359" stroke="#57f2c6" stroke-miterlimit="10" stroke-width="4"></polygon>
                <polygon points="766.84 978.13 871.92 917.46 871.92 796.12 766.84 856.79 766.84 978.13" fill="#2d3359" stroke="#57f2c6" stroke-miterlimit="10" stroke-width="4"></polygon>
              </g>
              <g id="Cube-46" data-name="Cube">
                <polygon points="661.84 796.46 556.76 857.12 661.84 917.79 766.92 857.12 661.84 796.46" fill="#2d3359" stroke="#57f2c6" stroke-miterlimit="10" stroke-width="4"></polygon>
                <polygon points="556.76 857.12 556.76 978.46 661.84 1039.13 661.84 917.79 556.76 857.12" fill="#2d3359" stroke="#57f2c6" stroke-miterlimit="10" stroke-width="4"></polygon>
                <polygon points="661.84 1039.13 766.92 978.46 766.92 857.12 661.84 917.79 661.84 1039.13" fill="#2d3359" stroke="#57f2c6" stroke-miterlimit="10" stroke-width="4"></polygon>
              </g>
              <g id="Cube-47" data-name="Cube">
                <polygon points="556.84 857.46 451.76 918.12 556.84 978.79 661.92 918.12 556.84 857.46" fill="#2d3359" stroke="#57f2c6" stroke-miterlimit="10" stroke-width="4"></polygon>
                <polygon points="451.76 918.12 451.76 1039.46 556.84 1100.13 556.84 978.79 451.76 918.12" fill="#2d3359" stroke="#57f2c6" stroke-miterlimit="10" stroke-width="4"></polygon>
                <polygon points="556.84 1100.13 661.92 1039.46 661.92 918.12 556.84 978.79 556.84 1100.13" fill="#2d3359" stroke="#57f2c6" stroke-miterlimit="10" stroke-width="4"></polygon>
              </g>
              <g id="Cube-48" data-name="Cube">
                <polygon points="451.84 918.46 346.76 979.12 451.84 1039.79 556.92 979.12 451.84 918.46" fill="#2d3359" stroke="#57f2c6" stroke-miterlimit="10" stroke-width="4"></polygon>
                <polygon points="346.76 979.12 346.76 1100.46 451.84 1161.13 451.84 1039.79 346.76 979.12" fill="#2d3359" stroke="#57f2c6" stroke-miterlimit="10" stroke-width="4"></polygon>
                <polygon points="451.84 1161.13 556.92 1100.46 556.92 979.12 451.84 1039.79 451.84 1161.13" fill="#2d3359" stroke="#57f2c6" stroke-miterlimit="10" stroke-width="4"></polygon>
              </g>
            </g>
            <g id="CubeGroup5">
              <g id="Cube-49" data-name="Cube">
                <polygon points="1711.84 308.46 1606.76 369.12 1711.84 429.79 1816.92 369.12 1711.84 308.46" fill="#2d3359" stroke="#57f2c6" stroke-miterlimit="10" stroke-width="4"></polygon>
                <polygon points="1606.76 369.12 1606.76 490.46 1711.84 551.13 1711.84 429.79 1606.76 369.12" fill="#2d3359" stroke="#57f2c6" stroke-miterlimit="10" stroke-width="4"></polygon>
                <polygon points="1711.84 551.13 1816.92 490.46 1816.92 369.12 1711.84 429.79 1711.84 551.13" fill="#2d3359" stroke="#57f2c6" stroke-miterlimit="10" stroke-width="4"></polygon>
              </g>
              <g id="Cube-50" data-name="Cube">
                <polygon points="1606.84 369.46 1501.76 430.12 1606.84 490.79 1711.92 430.12 1606.84 369.46" fill="#2d3359" stroke="#57f2c6" stroke-miterlimit="10" stroke-width="4"></polygon>
                <polygon points="1501.76 430.12 1501.76 551.46 1606.84 612.13 1606.84 490.79 1501.76 430.12" fill="#2d3359" stroke="#57f2c6" stroke-miterlimit="10" stroke-width="4"></polygon>
                <polygon points="1606.84 612.13 1711.92 551.46 1711.92 430.12 1606.84 490.79 1606.84 612.13" fill="#2d3359" stroke="#57f2c6" stroke-miterlimit="10" stroke-width="4"></polygon>
              </g>
              <g id="Cube-51" data-name="Cube">
                <polygon points="1501.84 430.46 1396.76 491.12 1501.84 551.79 1606.92 491.12 1501.84 430.46" fill="#2d3359" stroke="#57f2c6" stroke-miterlimit="10" stroke-width="4"></polygon>
                <polygon points="1396.76 491.12 1396.76 612.46 1501.84 673.13 1501.84 551.79 1396.76 491.12" fill="#2d3359" stroke="#57f2c6" stroke-miterlimit="10" stroke-width="4"></polygon>
                <polygon points="1501.84 673.13 1606.92 612.46 1606.92 491.12 1501.84 551.79 1501.84 673.13" fill="#2d3359" stroke="#57f2c6" stroke-miterlimit="10" stroke-width="4"></polygon>
              </g>
              <g id="Cube-52" data-name="Cube">
                <polygon points="1396.84 491.46 1291.76 552.12 1396.84 612.79 1501.92 552.12 1396.84 491.46" fill="#2d3359" stroke="#57f2c6" stroke-miterlimit="10" stroke-width="4"></polygon>
                <polygon points="1291.76 552.12 1291.76 673.46 1396.84 734.13 1396.84 612.79 1291.76 552.12" fill="#2d3359" stroke="#57f2c6" stroke-miterlimit="10" stroke-width="4"></polygon>
                <polygon points="1396.84 734.13 1501.92 673.46 1501.92 552.12 1396.84 612.79 1396.84 734.13" fill="#2d3359" stroke="#57f2c6" stroke-miterlimit="10" stroke-width="4"></polygon>
              </g>
              <g id="Cube-53" data-name="Cube">
                <polygon points="1291.84 552.46 1186.76 613.12 1291.84 673.79 1396.92 613.12 1291.84 552.46" fill="#2d3359" stroke="#57f2c6" stroke-miterlimit="10" stroke-width="4"></polygon>
                <polygon points="1186.76 613.12 1186.76 734.46 1291.84 795.13 1291.84 673.79 1186.76 613.12" fill="#2d3359" stroke="#57f2c6" stroke-miterlimit="10" stroke-width="4"></polygon>
                <polygon points="1291.84 795.13 1396.92 734.46 1396.92 613.12 1291.84 673.79 1291.84 795.13" fill="#2d3359" stroke="#57f2c6" stroke-miterlimit="10" stroke-width="4"></polygon>
              </g>
              <g id="Cube-54" data-name="Cube">
                <polygon points="1186.84 613.46 1081.76 674.12 1186.84 734.79 1291.92 674.12 1186.84 613.46" fill="#2d3359" stroke="#57f2c6" stroke-miterlimit="10" stroke-width="4"></polygon>
                <polygon points="1081.76 674.12 1081.76 795.46 1186.84 856.13 1186.84 734.79 1081.76 674.12" fill="#2d3359" stroke="#57f2c6" stroke-miterlimit="10" stroke-width="4"></polygon>
                <polygon points="1186.84 856.13 1291.92 795.46 1291.92 674.12 1186.84 734.79 1186.84 856.13" fill="#2d3359" stroke="#57f2c6" stroke-miterlimit="10" stroke-width="4"></polygon>
              </g>
              <g id="Cube-55" data-name="Cube">
                <polygon points="1081.84 674.46 976.76 735.12 1081.84 795.79 1186.92 735.12 1081.84 674.46" fill="#2d3359" stroke="#57f2c6" stroke-miterlimit="10" stroke-width="4"></polygon>
                <polygon points="976.76 735.12 976.76 856.46 1081.84 917.13 1081.84 795.79 976.76 735.12" fill="#2d3359" stroke="#57f2c6" stroke-miterlimit="10" stroke-width="4"></polygon>
                <polygon points="1081.84 917.13 1186.92 856.46 1186.92 735.12 1081.84 795.79 1081.84 917.13" fill="#2d3359" stroke="#57f2c6" stroke-miterlimit="10" stroke-width="4"></polygon>
              </g>
              <g id="Cube-56" data-name="Cube">
                <polygon points="976.84 735.46 871.76 796.12 976.84 856.79 1081.92 796.12 976.84 735.46" fill="#2d3359" stroke="#57f2c6" stroke-miterlimit="10" stroke-width="4"></polygon>
                <polygon points="871.76 796.12 871.76 917.46 976.84 978.13 976.84 856.79 871.76 796.12" fill="#2d3359" stroke="#57f2c6" stroke-miterlimit="10" stroke-width="4"></polygon>
                <polygon points="976.84 978.13 1081.92 917.46 1081.92 796.12 976.84 856.79 976.84 978.13" fill="#2d3359" stroke="#57f2c6" stroke-miterlimit="10" stroke-width="4"></polygon>
              </g>
              <g id="Cube-57" data-name="Cube">
                <polygon points="871.84 796.46 766.76 857.12 871.84 917.79 976.92 857.12 871.84 796.46" fill="#2d3359" stroke="#57f2c6" stroke-miterlimit="10" stroke-width="4"></polygon>
                <polygon points="766.76 857.12 766.76 978.46 871.84 1039.13 871.84 917.79 766.76 857.12" fill="#2d3359" stroke="#57f2c6" stroke-miterlimit="10" stroke-width="4"></polygon>
                <polygon points="871.84 1039.13 976.92 978.46 976.92 857.12 871.84 917.79 871.84 1039.13" fill="#2d3359" stroke="#57f2c6" stroke-miterlimit="10" stroke-width="4"></polygon>
              </g>
              <g id="Cube-58" data-name="Cube">
                <polygon points="766.84 857.46 661.76 918.12 766.84 978.79 871.92 918.12 766.84 857.46" fill="#2d3359" stroke="#57f2c6" stroke-miterlimit="10" stroke-width="4"></polygon>
                <polygon points="661.76 918.12 661.76 1039.46 766.84 1100.13 766.84 978.79 661.76 918.12" fill="#2d3359" stroke="#57f2c6" stroke-miterlimit="10" stroke-width="4"></polygon>
                <polygon points="766.84 1100.13 871.92 1039.46 871.92 918.12 766.84 978.79 766.84 1100.13" fill="#2d3359" stroke="#57f2c6" stroke-miterlimit="10" stroke-width="4"></polygon>
              </g>
              <g id="Cube-59" data-name="Cube">
                <polygon points="661.84 918.46 556.76 979.12 661.84 1039.79 766.92 979.12 661.84 918.46" fill="#2d3359" stroke="#57f2c6" stroke-miterlimit="10" stroke-width="4"></polygon>
                <polygon points="556.76 979.12 556.76 1100.46 661.84 1161.13 661.84 1039.79 556.76 979.12" fill="#2d3359" stroke="#57f2c6" stroke-miterlimit="10" stroke-width="4"></polygon>
                <polygon points="661.84 1161.13 766.92 1100.46 766.92 979.12 661.84 1039.79 661.84 1161.13" fill="#2d3359" stroke="#57f2c6" stroke-miterlimit="10" stroke-width="4"></polygon>
              </g>
              <g id="Cube-60" data-name="Cube">
                <polygon points="556.84 979.46 451.76 1040.12 556.84 1100.79 661.92 1040.12 556.84 979.46" fill="#2d3359" stroke="#57f2c6" stroke-miterlimit="10" stroke-width="4"></polygon>
                <polygon points="451.76 1040.12 451.76 1161.46 556.84 1222.13 556.84 1100.79 451.76 1040.12" fill="#2d3359" stroke="#57f2c6" stroke-miterlimit="10" stroke-width="4"></polygon>
                <polygon points="556.84 1222.13 661.92 1161.46 661.92 1040.12 556.84 1100.79 556.84 1222.13" fill="#2d3359" stroke="#57f2c6" stroke-miterlimit="10" stroke-width="4"></polygon>
              </g>
            </g>
            <g id="CubeGroup6">
              <g id="Cube-61" data-name="Cube">
                <polygon points="1816.84 369.46 1711.76 430.12 1816.84 490.79 1921.92 430.12 1816.84 369.46" fill="#2d3359" stroke="#57f2c6" stroke-miterlimit="10" stroke-width="4"></polygon>
                <polygon points="1711.76 430.12 1711.76 551.46 1816.84 612.13 1816.84 490.79 1711.76 430.12" fill="#2d3359" stroke="#57f2c6" stroke-miterlimit="10" stroke-width="4"></polygon>
                <polygon points="1816.84 612.13 1921.92 551.46 1921.92 430.12 1816.84 490.79 1816.84 612.13" fill="#2d3359" stroke="#57f2c6" stroke-miterlimit="10" stroke-width="4"></polygon>
              </g>
              <g id="Cube-62" data-name="Cube">
                <polygon points="1711.84 430.46 1606.76 491.12 1711.84 551.79 1816.92 491.12 1711.84 430.46" fill="#2d3359" stroke="#57f2c6" stroke-miterlimit="10" stroke-width="4"></polygon>
                <polygon points="1606.76 491.12 1606.76 612.46 1711.84 673.13 1711.84 551.79 1606.76 491.12" fill="#2d3359" stroke="#57f2c6" stroke-miterlimit="10" stroke-width="4"></polygon>
                <polygon points="1711.84 673.13 1816.92 612.46 1816.92 491.12 1711.84 551.79 1711.84 673.13" fill="#2d3359" stroke="#57f2c6" stroke-miterlimit="10" stroke-width="4"></polygon>
              </g>
              <g id="Cube-63" data-name="Cube">
                <polygon points="1606.84 491.46 1501.76 552.12 1606.84 612.79 1711.92 552.12 1606.84 491.46" fill="#2d3359" stroke="#57f2c6" stroke-miterlimit="10" stroke-width="4"></polygon>
                <polygon points="1501.76 552.12 1501.76 673.46 1606.84 734.13 1606.84 612.79 1501.76 552.12" fill="#2d3359" stroke="#57f2c6" stroke-miterlimit="10" stroke-width="4"></polygon>
                <polygon points="1606.84 734.13 1711.92 673.46 1711.92 552.12 1606.84 612.79 1606.84 734.13" fill="#2d3359" stroke="#57f2c6" stroke-miterlimit="10" stroke-width="4"></polygon>
              </g>
              <g id="Cube-64" data-name="Cube">
                <polygon points="1501.84 552.46 1396.76 613.12 1501.84 673.79 1606.92 613.12 1501.84 552.46" fill="#2d3359" stroke="#57f2c6" stroke-miterlimit="10" stroke-width="4"></polygon>
                <polygon points="1396.76 613.12 1396.76 734.46 1501.84 795.13 1501.84 673.79 1396.76 613.12" fill="#2d3359" stroke="#57f2c6" stroke-miterlimit="10" stroke-width="4"></polygon>
                <polygon points="1501.84 795.13 1606.92 734.46 1606.92 613.12 1501.84 673.79 1501.84 795.13" fill="#2d3359" stroke="#57f2c6" stroke-miterlimit="10" stroke-width="4"></polygon>
              </g>
              <g id="Cube-65" data-name="Cube">
                <polygon points="1396.84 613.46 1291.76 674.12 1396.84 734.79 1501.92 674.12 1396.84 613.46" fill="#2d3359" stroke="#57f2c6" stroke-miterlimit="10" stroke-width="4"></polygon>
                <polygon points="1291.76 674.12 1291.76 795.46 1396.84 856.13 1396.84 734.79 1291.76 674.12" fill="#2d3359" stroke="#57f2c6" stroke-miterlimit="10" stroke-width="4"></polygon>
                <polygon points="1396.84 856.13 1501.92 795.46 1501.92 674.12 1396.84 734.79 1396.84 856.13" fill="#2d3359" stroke="#57f2c6" stroke-miterlimit="10" stroke-width="4"></polygon>
              </g>
              <g id="Cube-66" data-name="Cube">
                <polygon points="1291.84 674.46 1186.76 735.12 1291.84 795.79 1396.92 735.12 1291.84 674.46" fill="#2d3359" stroke="#57f2c6" stroke-miterlimit="10" stroke-width="4"></polygon>
                <polygon points="1186.76 735.12 1186.76 856.46 1291.84 917.13 1291.84 795.79 1186.76 735.12" fill="#2d3359" stroke="#57f2c6" stroke-miterlimit="10" stroke-width="4"></polygon>
                <polygon points="1291.84 917.13 1396.92 856.46 1396.92 735.12 1291.84 795.79 1291.84 917.13" fill="#2d3359" stroke="#57f2c6" stroke-miterlimit="10" stroke-width="4"></polygon>
              </g>
              <g id="Cube-67" data-name="Cube">
                <polygon points="1186.84 735.46 1081.76 796.12 1186.84 856.79 1291.92 796.12 1186.84 735.46" fill="#2d3359" stroke="#57f2c6" stroke-miterlimit="10" stroke-width="4"></polygon>
                <polygon points="1081.76 796.12 1081.76 917.46 1186.84 978.13 1186.84 856.79 1081.76 796.12" fill="#2d3359" stroke="#57f2c6" stroke-miterlimit="10" stroke-width="4"></polygon>
                <polygon points="1186.84 978.13 1291.92 917.46 1291.92 796.12 1186.84 856.79 1186.84 978.13" fill="#2d3359" stroke="#57f2c6" stroke-miterlimit="10" stroke-width="4"></polygon>
              </g>
              <g id="Cube-68" data-name="Cube">
                <polygon points="1081.84 796.46 976.76 857.12 1081.84 917.79 1186.92 857.12 1081.84 796.46" fill="#2d3359" stroke="#57f2c6" stroke-miterlimit="10" stroke-width="4"></polygon>
                <polygon points="976.76 857.12 976.76 978.46 1081.84 1039.13 1081.84 917.79 976.76 857.12" fill="#2d3359" stroke="#57f2c6" stroke-miterlimit="10" stroke-width="4"></polygon>
                <polygon points="1081.84 1039.13 1186.92 978.46 1186.92 857.12 1081.84 917.79 1081.84 1039.13" fill="#2d3359" stroke="#57f2c6" stroke-miterlimit="10" stroke-width="4"></polygon>
              </g>
              <g id="Cube-69" data-name="Cube">
                <polygon points="976.84 857.46 871.76 918.12 976.84 978.79 1081.92 918.12 976.84 857.46" fill="#2d3359" stroke="#57f2c6" stroke-miterlimit="10" stroke-width="4"></polygon>
                <polygon points="871.76 918.12 871.76 1039.46 976.84 1100.13 976.84 978.79 871.76 918.12" fill="#2d3359" stroke="#57f2c6" stroke-miterlimit="10" stroke-width="4"></polygon>
                <polygon points="976.84 1100.13 1081.92 1039.46 1081.92 918.12 976.84 978.79 976.84 1100.13" fill="#2d3359" stroke="#57f2c6" stroke-miterlimit="10" stroke-width="4"></polygon>
              </g>
              <g id="Cube-70" data-name="Cube">
                <polygon points="871.84 918.46 766.76 979.12 871.84 1039.79 976.92 979.12 871.84 918.46" fill="#2d3359" stroke="#57f2c6" stroke-miterlimit="10" stroke-width="4"></polygon>
                <polygon points="766.76 979.12 766.76 1100.46 871.84 1161.13 871.84 1039.79 766.76 979.12" fill="#2d3359" stroke="#57f2c6" stroke-miterlimit="10" stroke-width="4"></polygon>
                <polygon points="871.84 1161.13 976.92 1100.46 976.92 979.12 871.84 1039.79 871.84 1161.13" fill="#2d3359" stroke="#57f2c6" stroke-miterlimit="10" stroke-width="4"></polygon>
              </g>
              <g id="Cube-71" data-name="Cube">
                <polygon points="766.84 979.46 661.76 1040.12 766.84 1100.79 871.92 1040.12 766.84 979.46" fill="#2d3359" stroke="#57f2c6" stroke-miterlimit="10" stroke-width="4"></polygon>
                <polygon points="661.76 1040.12 661.76 1161.46 766.84 1222.13 766.84 1100.79 661.76 1040.12" fill="#2d3359" stroke="#57f2c6" stroke-miterlimit="10" stroke-width="4"></polygon>
                <polygon points="766.84 1222.13 871.92 1161.46 871.92 1040.12 766.84 1100.79 766.84 1222.13" fill="#2d3359" stroke="#57f2c6" stroke-miterlimit="10" stroke-width="4"></polygon>
              </g>
              <g id="Cube-72" data-name="Cube">
                <polygon points="661.84 1040.46 556.76 1101.12 661.84 1161.79 766.92 1101.12 661.84 1040.46" fill="#2d3359" stroke="#57f2c6" stroke-miterlimit="10" stroke-width="4"></polygon>
                <polygon points="556.76 1101.12 556.76 1222.46 661.84 1283.13 661.84 1161.79 556.76 1101.12" fill="#2d3359" stroke="#57f2c6" stroke-miterlimit="10" stroke-width="4"></polygon>
                <polygon points="661.84 1283.13 766.92 1222.46 766.92 1101.12 661.84 1161.79 661.84 1283.13" fill="#2d3359" stroke="#57f2c6" stroke-miterlimit="10" stroke-width="4"></polygon>
              </g>
            </g>
            <g id="CubeGroup7">
              <g id="Cube-73" data-name="Cube">
                <polygon points="1921.84 430.46 1816.76 491.12 1921.84 551.79 2026.92 491.12 1921.84 430.46" fill="#2d3359" stroke="#57f2c6" stroke-miterlimit="10" stroke-width="4"></polygon>
                <polygon points="1816.76 491.12 1816.76 612.46 1921.84 673.13 1921.84 551.79 1816.76 491.12" fill="#2d3359" stroke="#57f2c6" stroke-miterlimit="10" stroke-width="4"></polygon>
                <polygon points="1921.84 673.13 2026.92 612.46 2026.92 491.12 1921.84 551.79 1921.84 673.13" fill="#2d3359" stroke="#57f2c6" stroke-miterlimit="10" stroke-width="4"></polygon>
              </g>
              <g id="Cube-74" data-name="Cube">
                <polygon points="1816.84 491.46 1711.76 552.12 1816.84 612.79 1921.92 552.12 1816.84 491.46" fill="#2d3359" stroke="#57f2c6" stroke-miterlimit="10" stroke-width="4"></polygon>
                <polygon points="1711.76 552.12 1711.76 673.46 1816.84 734.13 1816.84 612.79 1711.76 552.12" fill="#2d3359" stroke="#57f2c6" stroke-miterlimit="10" stroke-width="4"></polygon>
                <polygon points="1816.84 734.13 1921.92 673.46 1921.92 552.12 1816.84 612.79 1816.84 734.13" fill="#2d3359" stroke="#57f2c6" stroke-miterlimit="10" stroke-width="4"></polygon>
              </g>
              <g id="Cube-75" data-name="Cube">
                <polygon points="1711.84 552.46 1606.76 613.12 1711.84 673.79 1816.92 613.12 1711.84 552.46" fill="#2d3359" stroke="#57f2c6" stroke-miterlimit="10" stroke-width="4"></polygon>
                <polygon points="1606.76 613.12 1606.76 734.46 1711.84 795.13 1711.84 673.79 1606.76 613.12" fill="#2d3359" stroke="#57f2c6" stroke-miterlimit="10" stroke-width="4"></polygon>
                <polygon points="1711.84 795.13 1816.92 734.46 1816.92 613.12 1711.84 673.79 1711.84 795.13" fill="#2d3359" stroke="#57f2c6" stroke-miterlimit="10" stroke-width="4"></polygon>
              </g>
              <g id="Cube-76" data-name="Cube">
                <polygon points="1606.84 613.46 1501.76 674.12 1606.84 734.79 1711.92 674.12 1606.84 613.46" fill="#2d3359" stroke="#57f2c6" stroke-miterlimit="10" stroke-width="4"></polygon>
                <polygon points="1501.76 674.12 1501.76 795.46 1606.84 856.13 1606.84 734.79 1501.76 674.12" fill="#2d3359" stroke="#57f2c6" stroke-miterlimit="10" stroke-width="4"></polygon>
                <polygon points="1606.84 856.13 1711.92 795.46 1711.92 674.12 1606.84 734.79 1606.84 856.13" fill="#2d3359" stroke="#57f2c6" stroke-miterlimit="10" stroke-width="4"></polygon>
              </g>
              <g id="Cube-77" data-name="Cube">
                <polygon points="1501.84 674.46 1396.76 735.12 1501.84 795.79 1606.92 735.12 1501.84 674.46" fill="#2d3359" stroke="#57f2c6" stroke-miterlimit="10" stroke-width="4"></polygon>
                <polygon points="1396.76 735.12 1396.76 856.46 1501.84 917.13 1501.84 795.79 1396.76 735.12" fill="#2d3359" stroke="#57f2c6" stroke-miterlimit="10" stroke-width="4"></polygon>
                <polygon points="1501.84 917.13 1606.92 856.46 1606.92 735.12 1501.84 795.79 1501.84 917.13" fill="#2d3359" stroke="#57f2c6" stroke-miterlimit="10" stroke-width="4"></polygon>
              </g>
              <g id="Cube-78" data-name="Cube">
                <polygon points="1396.84 735.46 1291.76 796.12 1396.84 856.79 1501.92 796.12 1396.84 735.46" fill="#2d3359" stroke="#57f2c6" stroke-miterlimit="10" stroke-width="4"></polygon>
                <polygon points="1291.76 796.12 1291.76 917.46 1396.84 978.13 1396.84 856.79 1291.76 796.12" fill="#2d3359" stroke="#57f2c6" stroke-miterlimit="10" stroke-width="4"></polygon>
                <polygon points="1396.84 978.13 1501.92 917.46 1501.92 796.12 1396.84 856.79 1396.84 978.13" fill="#2d3359" stroke="#57f2c6" stroke-miterlimit="10" stroke-width="4"></polygon>
              </g>
              <g id="Cube-79" data-name="Cube">
                <polygon points="1291.84 796.46 1186.76 857.12 1291.84 917.79 1396.92 857.12 1291.84 796.46" fill="#2d3359" stroke="#57f2c6" stroke-miterlimit="10" stroke-width="4"></polygon>
                <polygon points="1186.76 857.12 1186.76 978.46 1291.84 1039.13 1291.84 917.79 1186.76 857.12" fill="#2d3359" stroke="#57f2c6" stroke-miterlimit="10" stroke-width="4"></polygon>
                <polygon points="1291.84 1039.13 1396.92 978.46 1396.92 857.12 1291.84 917.79 1291.84 1039.13" fill="#2d3359" stroke="#57f2c6" stroke-miterlimit="10" stroke-width="4"></polygon>
              </g>
              <g id="Cube-80" data-name="Cube">
                <polygon points="1186.84 857.46 1081.76 918.12 1186.84 978.79 1291.92 918.12 1186.84 857.46" fill="#2d3359" stroke="#57f2c6" stroke-miterlimit="10" stroke-width="4"></polygon>
                <polygon points="1081.76 918.12 1081.76 1039.46 1186.84 1100.13 1186.84 978.79 1081.76 918.12" fill="#2d3359" stroke="#57f2c6" stroke-miterlimit="10" stroke-width="4"></polygon>
                <polygon points="1186.84 1100.13 1291.92 1039.46 1291.92 918.12 1186.84 978.79 1186.84 1100.13" fill="#2d3359" stroke="#57f2c6" stroke-miterlimit="10" stroke-width="4"></polygon>
              </g>
              <g id="Cube-81" data-name="Cube">
                <polygon points="1081.84 918.46 976.76 979.12 1081.84 1039.79 1186.92 979.12 1081.84 918.46" fill="#2d3359" stroke="#57f2c6" stroke-miterlimit="10" stroke-width="4"></polygon>
                <polygon points="976.76 979.12 976.76 1100.46 1081.84 1161.13 1081.84 1039.79 976.76 979.12" fill="#2d3359" stroke="#57f2c6" stroke-miterlimit="10" stroke-width="4"></polygon>
                <polygon points="1081.84 1161.13 1186.92 1100.46 1186.92 979.12 1081.84 1039.79 1081.84 1161.13" fill="#2d3359" stroke="#57f2c6" stroke-miterlimit="10" stroke-width="4"></polygon>
              </g>
              <g id="Cube-82" data-name="Cube">
                <polygon points="976.84 979.46 871.76 1040.12 976.84 1100.79 1081.92 1040.12 976.84 979.46" fill="#2d3359" stroke="#57f2c6" stroke-miterlimit="10" stroke-width="4"></polygon>
                <polygon points="871.76 1040.12 871.76 1161.46 976.84 1222.13 976.84 1100.79 871.76 1040.12" fill="#2d3359" stroke="#57f2c6" stroke-miterlimit="10" stroke-width="4"></polygon>
                <polygon points="976.84 1222.13 1081.92 1161.46 1081.92 1040.12 976.84 1100.79 976.84 1222.13" fill="#2d3359" stroke="#57f2c6" stroke-miterlimit="10" stroke-width="4"></polygon>
              </g>
              <g id="Cube-83" data-name="Cube">
                <polygon points="871.84 1040.46 766.76 1101.12 871.84 1161.79 976.92 1101.12 871.84 1040.46" fill="#2d3359" stroke="#57f2c6" stroke-miterlimit="10" stroke-width="4"></polygon>
                <polygon points="766.76 1101.12 766.76 1222.46 871.84 1283.13 871.84 1161.79 766.76 1101.12" fill="#2d3359" stroke="#57f2c6" stroke-miterlimit="10" stroke-width="4"></polygon>
                <polygon points="871.84 1283.13 976.92 1222.46 976.92 1101.12 871.84 1161.79 871.84 1283.13" fill="#2d3359" stroke="#57f2c6" stroke-miterlimit="10" stroke-width="4"></polygon>
              </g>
              <g id="Cube-84" data-name="Cube">
                <polygon points="766.84 1101.46 661.76 1162.12 766.84 1222.79 871.92 1162.12 766.84 1101.46" fill="#2d3359" stroke="#57f2c6" stroke-miterlimit="10" stroke-width="4"></polygon>
                <polygon points="661.76 1162.12 661.76 1283.46 766.84 1344.13 766.84 1222.79 661.76 1162.12" fill="#2d3359" stroke="#57f2c6" stroke-miterlimit="10" stroke-width="4"></polygon>
                <polygon points="766.84 1344.13 871.92 1283.46 871.92 1162.12 766.84 1222.79 766.84 1344.13" fill="#2d3359" stroke="#57f2c6" stroke-miterlimit="10" stroke-width="4"></polygon>
              </g>
            </g>
            <g id="CubeGroup8">
              <g id="Cube-85" data-name="Cube">
                <polygon points="2026.84 491.46 1921.76 552.12 2026.84 612.79 2131.92 552.12 2026.84 491.46" fill="#2d3359" stroke="#57f2c6" stroke-miterlimit="10" stroke-width="4"></polygon>
                <polygon points="1921.76 552.12 1921.76 673.46 2026.84 734.13 2026.84 612.79 1921.76 552.12" fill="#2d3359" stroke="#57f2c6" stroke-miterlimit="10" stroke-width="4"></polygon>
                <polygon points="2026.84 734.13 2131.92 673.46 2131.92 552.12 2026.84 612.79 2026.84 734.13" fill="#2d3359" stroke="#57f2c6" stroke-miterlimit="10" stroke-width="4"></polygon>
              </g>
              <g id="Cube-86" data-name="Cube">
                <polygon points="1921.84 552.46 1816.76 613.12 1921.84 673.79 2026.92 613.12 1921.84 552.46" fill="#2d3359" stroke="#57f2c6" stroke-miterlimit="10" stroke-width="4"></polygon>
                <polygon points="1816.76 613.12 1816.76 734.46 1921.84 795.13 1921.84 673.79 1816.76 613.12" fill="#2d3359" stroke="#57f2c6" stroke-miterlimit="10" stroke-width="4"></polygon>
                <polygon points="1921.84 795.13 2026.92 734.46 2026.92 613.12 1921.84 673.79 1921.84 795.13" fill="#2d3359" stroke="#57f2c6" stroke-miterlimit="10" stroke-width="4"></polygon>
              </g>
              <g id="Cube-87" data-name="Cube">
                <polygon points="1816.84 613.46 1711.76 674.12 1816.84 734.79 1921.92 674.12 1816.84 613.46" fill="#2d3359" stroke="#57f2c6" stroke-miterlimit="10" stroke-width="4"></polygon>
                <polygon points="1711.76 674.12 1711.76 795.46 1816.84 856.13 1816.84 734.79 1711.76 674.12" fill="#2d3359" stroke="#57f2c6" stroke-miterlimit="10" stroke-width="4"></polygon>
                <polygon points="1816.84 856.13 1921.92 795.46 1921.92 674.12 1816.84 734.79 1816.84 856.13" fill="#2d3359" stroke="#57f2c6" stroke-miterlimit="10" stroke-width="4"></polygon>
              </g>
              <g id="Cube-88" data-name="Cube">
                <polygon points="1711.84 674.46 1606.76 735.12 1711.84 795.79 1816.92 735.12 1711.84 674.46" fill="#2d3359" stroke="#57f2c6" stroke-miterlimit="10" stroke-width="4"></polygon>
                <polygon points="1606.76 735.12 1606.76 856.46 1711.84 917.13 1711.84 795.79 1606.76 735.12" fill="#2d3359" stroke="#57f2c6" stroke-miterlimit="10" stroke-width="4"></polygon>
                <polygon points="1711.84 917.13 1816.92 856.46 1816.92 735.12 1711.84 795.79 1711.84 917.13" fill="#2d3359" stroke="#57f2c6" stroke-miterlimit="10" stroke-width="4"></polygon>
              </g>
              <g id="Cube-89" data-name="Cube">
                <polygon points="1606.84 735.46 1501.76 796.12 1606.84 856.79 1711.92 796.12 1606.84 735.46" fill="#2d3359" stroke="#57f2c6" stroke-miterlimit="10" stroke-width="4"></polygon>
                <polygon points="1501.76 796.12 1501.76 917.46 1606.84 978.13 1606.84 856.79 1501.76 796.12" fill="#2d3359" stroke="#57f2c6" stroke-miterlimit="10" stroke-width="4"></polygon>
                <polygon points="1606.84 978.13 1711.92 917.46 1711.92 796.12 1606.84 856.79 1606.84 978.13" fill="#2d3359" stroke="#57f2c6" stroke-miterlimit="10" stroke-width="4"></polygon>
              </g>
              <g id="Cube-90" data-name="Cube">
                <polygon points="1501.84 796.46 1396.76 857.12 1501.84 917.79 1606.92 857.12 1501.84 796.46" fill="#2d3359" stroke="#57f2c6" stroke-miterlimit="10" stroke-width="4"></polygon>
                <polygon points="1396.76 857.12 1396.76 978.46 1501.84 1039.13 1501.84 917.79 1396.76 857.12" fill="#2d3359" stroke="#57f2c6" stroke-miterlimit="10" stroke-width="4"></polygon>
                <polygon points="1501.84 1039.13 1606.92 978.46 1606.92 857.12 1501.84 917.79 1501.84 1039.13" fill="#2d3359" stroke="#57f2c6" stroke-miterlimit="10" stroke-width="4"></polygon>
              </g>
              <g id="Cube-91" data-name="Cube">
                <polygon points="1396.84 857.46 1291.76 918.12 1396.84 978.79 1501.92 918.12 1396.84 857.46" fill="#2d3359" stroke="#57f2c6" stroke-miterlimit="10" stroke-width="4"></polygon>
                <polygon points="1291.76 918.12 1291.76 1039.46 1396.84 1100.13 1396.84 978.79 1291.76 918.12" fill="#2d3359" stroke="#57f2c6" stroke-miterlimit="10" stroke-width="4"></polygon>
                <polygon points="1396.84 1100.13 1501.92 1039.46 1501.92 918.12 1396.84 978.79 1396.84 1100.13" fill="#2d3359" stroke="#57f2c6" stroke-miterlimit="10" stroke-width="4"></polygon>
              </g>
              <g id="Cube-92" data-name="Cube">
                <polygon points="1291.84 918.46 1186.76 979.12 1291.84 1039.79 1396.92 979.12 1291.84 918.46" fill="#2d3359" stroke="#57f2c6" stroke-miterlimit="10" stroke-width="4"></polygon>
                <polygon points="1186.76 979.12 1186.76 1100.46 1291.84 1161.13 1291.84 1039.79 1186.76 979.12" fill="#2d3359" stroke="#57f2c6" stroke-miterlimit="10" stroke-width="4"></polygon>
                <polygon points="1291.84 1161.13 1396.92 1100.46 1396.92 979.12 1291.84 1039.79 1291.84 1161.13" fill="#2d3359" stroke="#57f2c6" stroke-miterlimit="10" stroke-width="4"></polygon>
              </g>
              <g id="Cube-93" data-name="Cube">
                <polygon points="1186.84 979.46 1081.76 1040.12 1186.84 1100.79 1291.92 1040.12 1186.84 979.46" fill="#2d3359" stroke="#57f2c6" stroke-miterlimit="10" stroke-width="4"></polygon>
                <polygon points="1081.76 1040.12 1081.76 1161.46 1186.84 1222.13 1186.84 1100.79 1081.76 1040.12" fill="#2d3359" stroke="#57f2c6" stroke-miterlimit="10" stroke-width="4"></polygon>
                <polygon points="1186.84 1222.13 1291.92 1161.46 1291.92 1040.12 1186.84 1100.79 1186.84 1222.13" fill="#2d3359" stroke="#57f2c6" stroke-miterlimit="10" stroke-width="4"></polygon>
              </g>
              <g id="Cube-94" data-name="Cube">
                <polygon points="1081.84 1040.46 976.76 1101.12 1081.84 1161.79 1186.92 1101.12 1081.84 1040.46" fill="#2d3359" stroke="#57f2c6" stroke-miterlimit="10" stroke-width="4"></polygon>
                <polygon points="976.76 1101.12 976.76 1222.46 1081.84 1283.13 1081.84 1161.79 976.76 1101.12" fill="#2d3359" stroke="#57f2c6" stroke-miterlimit="10" stroke-width="4"></polygon>
                <polygon points="1081.84 1283.13 1186.92 1222.46 1186.92 1101.12 1081.84 1161.79 1081.84 1283.13" fill="#2d3359" stroke="#57f2c6" stroke-miterlimit="10" stroke-width="4"></polygon>
              </g>
              <g id="Cube-95" data-name="Cube">
                <polygon points="976.84 1101.46 871.76 1162.12 976.84 1222.79 1081.92 1162.12 976.84 1101.46" fill="#2d3359" stroke="#57f2c6" stroke-miterlimit="10" stroke-width="4"></polygon>
                <polygon points="871.76 1162.12 871.76 1283.46 976.84 1344.13 976.84 1222.79 871.76 1162.12" fill="#2d3359" stroke="#57f2c6" stroke-miterlimit="10" stroke-width="4"></polygon>
                <polygon points="976.84 1344.13 1081.92 1283.46 1081.92 1162.12 976.84 1222.79 976.84 1344.13" fill="#2d3359" stroke="#57f2c6" stroke-miterlimit="10" stroke-width="4"></polygon>
              </g>
              <g id="Cube-96" data-name="Cube">
                <polygon points="871.84 1162.46 766.76 1223.12 871.84 1283.79 976.92 1223.12 871.84 1162.46" fill="#2d3359" stroke="#57f2c6" stroke-miterlimit="10" stroke-width="4"></polygon>
                <polygon points="766.76 1223.12 766.76 1344.46 871.84 1405.13 871.84 1283.79 766.76 1223.12" fill="#2d3359" stroke="#57f2c6" stroke-miterlimit="10" stroke-width="4"></polygon>
                <polygon points="871.84 1405.13 976.92 1344.46 976.92 1223.12 871.84 1283.79 871.84 1405.13" fill="#2d3359" stroke="#57f2c6" stroke-miterlimit="10" stroke-width="4"></polygon>
              </g>
            </g>
            <g id="CubeGroup9">
              <g id="Cube-97" data-name="Cube">
                <polygon points="2131.84 552.46 2026.76 613.12 2131.84 673.79 2236.92 613.12 2131.84 552.46" fill="#2d3359" stroke="#57f2c6" stroke-miterlimit="10" stroke-width="4"></polygon>
                <polygon points="2026.76 613.12 2026.76 734.46 2131.84 795.13 2131.84 673.79 2026.76 613.12" fill="#2d3359" stroke="#57f2c6" stroke-miterlimit="10" stroke-width="4"></polygon>
                <polygon points="2131.84 795.13 2236.92 734.46 2236.92 613.12 2131.84 673.79 2131.84 795.13" fill="#2d3359" stroke="#57f2c6" stroke-miterlimit="10" stroke-width="4"></polygon>
              </g>
              <g id="Cube-98" data-name="Cube">
                <polygon points="2026.84 613.46 1921.76 674.12 2026.84 734.79 2131.92 674.12 2026.84 613.46" fill="#2d3359" stroke="#57f2c6" stroke-miterlimit="10" stroke-width="4"></polygon>
                <polygon points="1921.76 674.12 1921.76 795.46 2026.84 856.13 2026.84 734.79 1921.76 674.12" fill="#2d3359" stroke="#57f2c6" stroke-miterlimit="10" stroke-width="4"></polygon>
                <polygon points="2026.84 856.13 2131.92 795.46 2131.92 674.12 2026.84 734.79 2026.84 856.13" fill="#2d3359" stroke="#57f2c6" stroke-miterlimit="10" stroke-width="4"></polygon>
              </g>
              <g id="Cube-99" data-name="Cube">
                <polygon points="1921.84 674.46 1816.76 735.12 1921.84 795.79 2026.92 735.12 1921.84 674.46" fill="#2d3359" stroke="#57f2c6" stroke-miterlimit="10" stroke-width="4"></polygon>
                <polygon points="1816.76 735.12 1816.76 856.46 1921.84 917.13 1921.84 795.79 1816.76 735.12" fill="#2d3359" stroke="#57f2c6" stroke-miterlimit="10" stroke-width="4"></polygon>
                <polygon points="1921.84 917.13 2026.92 856.46 2026.92 735.12 1921.84 795.79 1921.84 917.13" fill="#2d3359" stroke="#57f2c6" stroke-miterlimit="10" stroke-width="4"></polygon>
              </g>
              <g id="Cube-100" data-name="Cube">
                <polygon points="1816.84 735.46 1711.76 796.12 1816.84 856.79 1921.92 796.12 1816.84 735.46" fill="#2d3359" stroke="#57f2c6" stroke-miterlimit="10" stroke-width="4"></polygon>
                <polygon points="1711.76 796.12 1711.76 917.46 1816.84 978.13 1816.84 856.79 1711.76 796.12" fill="#2d3359" stroke="#57f2c6" stroke-miterlimit="10" stroke-width="4"></polygon>
                <polygon points="1816.84 978.13 1921.92 917.46 1921.92 796.12 1816.84 856.79 1816.84 978.13" fill="#2d3359" stroke="#57f2c6" stroke-miterlimit="10" stroke-width="4"></polygon>
              </g>
              <g id="Cube-101" data-name="Cube">
                <polygon points="1711.84 796.46 1606.76 857.12 1711.84 917.79 1816.92 857.12 1711.84 796.46" fill="#2d3359" stroke="#57f2c6" stroke-miterlimit="10" stroke-width="4"></polygon>
                <polygon points="1606.76 857.12 1606.76 978.46 1711.84 1039.13 1711.84 917.79 1606.76 857.12" fill="#2d3359" stroke="#57f2c6" stroke-miterlimit="10" stroke-width="4"></polygon>
                <polygon points="1711.84 1039.13 1816.92 978.46 1816.92 857.12 1711.84 917.79 1711.84 1039.13" fill="#2d3359" stroke="#57f2c6" stroke-miterlimit="10" stroke-width="4"></polygon>
              </g>
              <g id="Cube-102" data-name="Cube">
                <polygon points="1606.84 857.46 1501.76 918.12 1606.84 978.79 1711.92 918.12 1606.84 857.46" fill="#2d3359" stroke="#57f2c6" stroke-miterlimit="10" stroke-width="4"></polygon>
                <polygon points="1501.76 918.12 1501.76 1039.46 1606.84 1100.13 1606.84 978.79 1501.76 918.12" fill="#2d3359" stroke="#57f2c6" stroke-miterlimit="10" stroke-width="4"></polygon>
                <polygon points="1606.84 1100.13 1711.92 1039.46 1711.92 918.12 1606.84 978.79 1606.84 1100.13" fill="#2d3359" stroke="#57f2c6" stroke-miterlimit="10" stroke-width="4"></polygon>
              </g>
              <g id="Cube-103" data-name="Cube">
                <polygon points="1501.84 918.46 1396.76 979.12 1501.84 1039.79 1606.92 979.12 1501.84 918.46" fill="#2d3359" stroke="#57f2c6" stroke-miterlimit="10" stroke-width="4"></polygon>
                <polygon points="1396.76 979.12 1396.76 1100.46 1501.84 1161.13 1501.84 1039.79 1396.76 979.12" fill="#2d3359" stroke="#57f2c6" stroke-miterlimit="10" stroke-width="4"></polygon>
                <polygon points="1501.84 1161.13 1606.92 1100.46 1606.92 979.12 1501.84 1039.79 1501.84 1161.13" fill="#2d3359" stroke="#57f2c6" stroke-miterlimit="10" stroke-width="4"></polygon>
              </g>
              <g id="Cube-104" data-name="Cube">
                <polygon points="1396.84 979.46 1291.76 1040.12 1396.84 1100.79 1501.92 1040.12 1396.84 979.46" fill="#2d3359" stroke="#57f2c6" stroke-miterlimit="10" stroke-width="4"></polygon>
                <polygon points="1291.76 1040.12 1291.76 1161.46 1396.84 1222.13 1396.84 1100.79 1291.76 1040.12" fill="#2d3359" stroke="#57f2c6" stroke-miterlimit="10" stroke-width="4"></polygon>
                <polygon points="1396.84 1222.13 1501.92 1161.46 1501.92 1040.12 1396.84 1100.79 1396.84 1222.13" fill="#2d3359" stroke="#57f2c6" stroke-miterlimit="10" stroke-width="4"></polygon>
              </g>
              <g id="Cube-105" data-name="Cube">
                <polygon points="1291.84 1040.46 1186.76 1101.12 1291.84 1161.79 1396.92 1101.12 1291.84 1040.46" fill="#2d3359" stroke="#57f2c6" stroke-miterlimit="10" stroke-width="4"></polygon>
                <polygon points="1186.76 1101.12 1186.76 1222.46 1291.84 1283.13 1291.84 1161.79 1186.76 1101.12" fill="#2d3359" stroke="#57f2c6" stroke-miterlimit="10" stroke-width="4"></polygon>
                <polygon points="1291.84 1283.13 1396.92 1222.46 1396.92 1101.12 1291.84 1161.79 1291.84 1283.13" fill="#2d3359" stroke="#57f2c6" stroke-miterlimit="10" stroke-width="4"></polygon>
              </g>
              <g id="Cube-106" data-name="Cube">
                <polygon points="1186.84 1101.46 1081.76 1162.12 1186.84 1222.79 1291.92 1162.12 1186.84 1101.46" fill="#2d3359" stroke="#57f2c6" stroke-miterlimit="10" stroke-width="4"></polygon>
                <polygon points="1081.76 1162.12 1081.76 1283.46 1186.84 1344.13 1186.84 1222.79 1081.76 1162.12" fill="#2d3359" stroke="#57f2c6" stroke-miterlimit="10" stroke-width="4"></polygon>
                <polygon points="1186.84 1344.13 1291.92 1283.46 1291.92 1162.12 1186.84 1222.79 1186.84 1344.13" fill="#2d3359" stroke="#57f2c6" stroke-miterlimit="10" stroke-width="4"></polygon>
              </g>
              <g id="Cube-107" data-name="Cube">
                <polygon points="1081.84 1162.46 976.76 1223.12 1081.84 1283.79 1186.92 1223.12 1081.84 1162.46" fill="#2d3359" stroke="#57f2c6" stroke-miterlimit="10" stroke-width="4"></polygon>
                <polygon points="976.76 1223.12 976.76 1344.46 1081.84 1405.13 1081.84 1283.79 976.76 1223.12" fill="#2d3359" stroke="#57f2c6" stroke-miterlimit="10" stroke-width="4"></polygon>
                <polygon points="1081.84 1405.13 1186.92 1344.46 1186.92 1223.12 1081.84 1283.79 1081.84 1405.13" fill="#2d3359" stroke="#57f2c6" stroke-miterlimit="10" stroke-width="4"></polygon>
              </g>
              <g id="Cube-108" data-name="Cube">
                <polygon points="976.84 1223.46 871.76 1284.12 976.84 1344.79 1081.92 1284.12 976.84 1223.46" fill="#2d3359" stroke="#57f2c6" stroke-miterlimit="10" stroke-width="4"></polygon>
                <polygon points="871.76 1284.12 871.76 1405.46 976.84 1466.13 976.84 1344.79 871.76 1284.12" fill="#2d3359" stroke="#57f2c6" stroke-miterlimit="10" stroke-width="4"></polygon>
                <polygon points="976.84 1466.13 1081.92 1405.46 1081.92 1284.12 976.84 1344.79 976.84 1466.13" fill="#2d3359" stroke="#57f2c6" stroke-miterlimit="10" stroke-width="4"></polygon>
              </g>
            </g>
            <g id="CubeGroup10">
              <g id="Cube-109" data-name="Cube">
                <polygon points="2236.84 613.46 2131.76 674.12 2236.84 734.79 2341.92 674.12 2236.84 613.46" fill="#2d3359" stroke="#57f2c6" stroke-miterlimit="10" stroke-width="4"></polygon>
                <polygon points="2131.76 674.12 2131.76 795.46 2236.84 856.13 2236.84 734.79 2131.76 674.12" fill="#2d3359" stroke="#57f2c6" stroke-miterlimit="10" stroke-width="4"></polygon>
                <polygon points="2236.84 856.13 2341.92 795.46 2341.92 674.12 2236.84 734.79 2236.84 856.13" fill="#2d3359" stroke="#57f2c6" stroke-miterlimit="10" stroke-width="4"></polygon>
              </g>
              <g id="Cube-110" data-name="Cube">
                <polygon points="2131.84 674.46 2026.76 735.12 2131.84 795.79 2236.92 735.12 2131.84 674.46" fill="#2d3359" stroke="#57f2c6" stroke-miterlimit="10" stroke-width="4"></polygon>
                <polygon points="2026.76 735.12 2026.76 856.46 2131.84 917.13 2131.84 795.79 2026.76 735.12" fill="#2d3359" stroke="#57f2c6" stroke-miterlimit="10" stroke-width="4"></polygon>
                <polygon points="2131.84 917.13 2236.92 856.46 2236.92 735.12 2131.84 795.79 2131.84 917.13" fill="#2d3359" stroke="#57f2c6" stroke-miterlimit="10" stroke-width="4"></polygon>
              </g>
              <g id="Cube-111" data-name="Cube">
                <polygon points="2026.84 735.46 1921.76 796.12 2026.84 856.79 2131.92 796.12 2026.84 735.46" fill="#2d3359" stroke="#57f2c6" stroke-miterlimit="10" stroke-width="4"></polygon>
                <polygon points="1921.76 796.12 1921.76 917.46 2026.84 978.13 2026.84 856.79 1921.76 796.12" fill="#2d3359" stroke="#57f2c6" stroke-miterlimit="10" stroke-width="4"></polygon>
                <polygon points="2026.84 978.13 2131.92 917.46 2131.92 796.12 2026.84 856.79 2026.84 978.13" fill="#2d3359" stroke="#57f2c6" stroke-miterlimit="10" stroke-width="4"></polygon>
              </g>
              <g id="Cube-112" data-name="Cube">
                <polygon points="1921.84 796.46 1816.76 857.12 1921.84 917.79 2026.92 857.12 1921.84 796.46" fill="#2d3359" stroke="#57f2c6" stroke-miterlimit="10" stroke-width="4"></polygon>
                <polygon points="1816.76 857.12 1816.76 978.46 1921.84 1039.13 1921.84 917.79 1816.76 857.12" fill="#2d3359" stroke="#57f2c6" stroke-miterlimit="10" stroke-width="4"></polygon>
                <polygon points="1921.84 1039.13 2026.92 978.46 2026.92 857.12 1921.84 917.79 1921.84 1039.13" fill="#2d3359" stroke="#57f2c6" stroke-miterlimit="10" stroke-width="4"></polygon>
              </g>
              <g id="Cube-113" data-name="Cube">
                <polygon points="1816.84 857.46 1711.76 918.12 1816.84 978.79 1921.92 918.12 1816.84 857.46" fill="#2d3359" stroke="#57f2c6" stroke-miterlimit="10" stroke-width="4"></polygon>
                <polygon points="1711.76 918.12 1711.76 1039.46 1816.84 1100.13 1816.84 978.79 1711.76 918.12" fill="#2d3359" stroke="#57f2c6" stroke-miterlimit="10" stroke-width="4"></polygon>
                <polygon points="1816.84 1100.13 1921.92 1039.46 1921.92 918.12 1816.84 978.79 1816.84 1100.13" fill="#2d3359" stroke="#57f2c6" stroke-miterlimit="10" stroke-width="4"></polygon>
              </g>
              <g id="Cube-114" data-name="Cube">
                <polygon points="1711.84 918.46 1606.76 979.12 1711.84 1039.79 1816.92 979.12 1711.84 918.46" fill="#2d3359" stroke="#57f2c6" stroke-miterlimit="10" stroke-width="4"></polygon>
                <polygon points="1606.76 979.12 1606.76 1100.46 1711.84 1161.13 1711.84 1039.79 1606.76 979.12" fill="#2d3359" stroke="#57f2c6" stroke-miterlimit="10" stroke-width="4"></polygon>
                <polygon points="1711.84 1161.13 1816.92 1100.46 1816.92 979.12 1711.84 1039.79 1711.84 1161.13" fill="#2d3359" stroke="#57f2c6" stroke-miterlimit="10" stroke-width="4"></polygon>
              </g>
              <g id="Cube-115" data-name="Cube">
                <polygon points="1606.84 979.46 1501.76 1040.12 1606.84 1100.79 1711.92 1040.12 1606.84 979.46" fill="#2d3359" stroke="#57f2c6" stroke-miterlimit="10" stroke-width="4"></polygon>
                <polygon points="1501.76 1040.12 1501.76 1161.46 1606.84 1222.13 1606.84 1100.79 1501.76 1040.12" fill="#2d3359" stroke="#57f2c6" stroke-miterlimit="10" stroke-width="4"></polygon>
                <polygon points="1606.84 1222.13 1711.92 1161.46 1711.92 1040.12 1606.84 1100.79 1606.84 1222.13" fill="#2d3359" stroke="#57f2c6" stroke-miterlimit="10" stroke-width="4"></polygon>
              </g>
              <g id="Cube-116" data-name="Cube">
                <polygon points="1501.84 1040.46 1396.76 1101.12 1501.84 1161.79 1606.92 1101.12 1501.84 1040.46" fill="#2d3359" stroke="#57f2c6" stroke-miterlimit="10" stroke-width="4"></polygon>
                <polygon points="1396.76 1101.12 1396.76 1222.46 1501.84 1283.13 1501.84 1161.79 1396.76 1101.12" fill="#2d3359" stroke="#57f2c6" stroke-miterlimit="10" stroke-width="4"></polygon>
                <polygon points="1501.84 1283.13 1606.92 1222.46 1606.92 1101.12 1501.84 1161.79 1501.84 1283.13" fill="#2d3359" stroke="#57f2c6" stroke-miterlimit="10" stroke-width="4"></polygon>
              </g>
              <g id="Cube-117" data-name="Cube">
                <polygon points="1396.84 1101.46 1291.76 1162.12 1396.84 1222.79 1501.92 1162.12 1396.84 1101.46" fill="#2d3359" stroke="#57f2c6" stroke-miterlimit="10" stroke-width="4"></polygon>
                <polygon points="1291.76 1162.12 1291.76 1283.46 1396.84 1344.13 1396.84 1222.79 1291.76 1162.12" fill="#2d3359" stroke="#57f2c6" stroke-miterlimit="10" stroke-width="4"></polygon>
                <polygon points="1396.84 1344.13 1501.92 1283.46 1501.92 1162.12 1396.84 1222.79 1396.84 1344.13" fill="#2d3359" stroke="#57f2c6" stroke-miterlimit="10" stroke-width="4"></polygon>
              </g>
              <g id="Cube-118" data-name="Cube">
                <polygon points="1291.84 1162.46 1186.76 1223.12 1291.84 1283.79 1396.92 1223.12 1291.84 1162.46" fill="#2d3359" stroke="#57f2c6" stroke-miterlimit="10" stroke-width="4"></polygon>
                <polygon points="1186.76 1223.12 1186.76 1344.46 1291.84 1405.13 1291.84 1283.79 1186.76 1223.12" fill="#2d3359" stroke="#57f2c6" stroke-miterlimit="10" stroke-width="4"></polygon>
                <polygon points="1291.84 1405.13 1396.92 1344.46 1396.92 1223.12 1291.84 1283.79 1291.84 1405.13" fill="#2d3359" stroke="#57f2c6" stroke-miterlimit="10" stroke-width="4"></polygon>
              </g>
              <g id="Cube-119" data-name="Cube">
                <polygon points="1186.84 1223.46 1081.76 1284.12 1186.84 1344.79 1291.92 1284.12 1186.84 1223.46" fill="#2d3359" stroke="#57f2c6" stroke-miterlimit="10" stroke-width="4"></polygon>
                <polygon points="1081.76 1284.12 1081.76 1405.46 1186.84 1466.13 1186.84 1344.79 1081.76 1284.12" fill="#2d3359" stroke="#57f2c6" stroke-miterlimit="10" stroke-width="4"></polygon>
                <polygon points="1186.84 1466.13 1291.92 1405.46 1291.92 1284.12 1186.84 1344.79 1186.84 1466.13" fill="#2d3359" stroke="#57f2c6" stroke-miterlimit="10" stroke-width="4"></polygon>
              </g>
              <g id="Cube-120" data-name="Cube">
                <polygon points="1081.84 1284.46 976.76 1345.12 1081.84 1405.79 1186.92 1345.12 1081.84 1284.46" fill="#2d3359" stroke="#57f2c6" stroke-miterlimit="10" stroke-width="4"></polygon>
                <polygon points="976.76 1345.12 976.76 1466.46 1081.84 1527.13 1081.84 1405.79 976.76 1345.12" fill="#2d3359" stroke="#57f2c6" stroke-miterlimit="10" stroke-width="4"></polygon>
                <polygon points="1081.84 1527.13 1186.92 1466.46 1186.92 1345.12 1081.84 1405.79 1081.84 1527.13" fill="#2d3359" stroke="#57f2c6" stroke-miterlimit="10" stroke-width="4"></polygon>
              </g>
            </g>
            <g id="CubeGroup11">
              <g id="Cube-121" data-name="Cube">
                <polygon points="2341.84 674.46 2236.76 735.12 2341.84 795.79 2446.92 735.12 2341.84 674.46" fill="#2d3359" stroke="#57f2c6" stroke-miterlimit="10" stroke-width="4"></polygon>
                <polygon points="2236.76 735.12 2236.76 856.46 2341.84 917.13 2341.84 795.79 2236.76 735.12" fill="#2d3359" stroke="#57f2c6" stroke-miterlimit="10" stroke-width="4"></polygon>
                <polygon points="2341.84 917.13 2446.92 856.46 2446.92 735.12 2341.84 795.79 2341.84 917.13" fill="#2d3359" stroke="#57f2c6" stroke-miterlimit="10" stroke-width="4"></polygon>
              </g>
              <g id="Cube-122" data-name="Cube">
                <polygon points="2236.84 735.46 2131.76 796.12 2236.84 856.79 2341.92 796.12 2236.84 735.46" fill="#2d3359" stroke="#57f2c6" stroke-miterlimit="10" stroke-width="4"></polygon>
                <polygon points="2131.76 796.12 2131.76 917.46 2236.84 978.13 2236.84 856.79 2131.76 796.12" fill="#2d3359" stroke="#57f2c6" stroke-miterlimit="10" stroke-width="4"></polygon>
                <polygon points="2236.84 978.13 2341.92 917.46 2341.92 796.12 2236.84 856.79 2236.84 978.13" fill="#2d3359" stroke="#57f2c6" stroke-miterlimit="10" stroke-width="4"></polygon>
              </g>
              <g id="Cube-123" data-name="Cube">
                <polygon points="2131.84 796.46 2026.76 857.12 2131.84 917.79 2236.92 857.12 2131.84 796.46" fill="#2d3359" stroke="#57f2c6" stroke-miterlimit="10" stroke-width="4"></polygon>
                <polygon points="2026.76 857.12 2026.76 978.46 2131.84 1039.13 2131.84 917.79 2026.76 857.12" fill="#2d3359" stroke="#57f2c6" stroke-miterlimit="10" stroke-width="4"></polygon>
                <polygon points="2131.84 1039.13 2236.92 978.46 2236.92 857.12 2131.84 917.79 2131.84 1039.13" fill="#2d3359" stroke="#57f2c6" stroke-miterlimit="10" stroke-width="4"></polygon>
              </g>
              <g id="Cube-124" data-name="Cube">
                <polygon points="2026.84 857.46 1921.76 918.12 2026.84 978.79 2131.92 918.12 2026.84 857.46" fill="#2d3359" stroke="#57f2c6" stroke-miterlimit="10" stroke-width="4"></polygon>
                <polygon points="1921.76 918.12 1921.76 1039.46 2026.84 1100.13 2026.84 978.79 1921.76 918.12" fill="#2d3359" stroke="#57f2c6" stroke-miterlimit="10" stroke-width="4"></polygon>
                <polygon points="2026.84 1100.13 2131.92 1039.46 2131.92 918.12 2026.84 978.79 2026.84 1100.13" fill="#2d3359" stroke="#57f2c6" stroke-miterlimit="10" stroke-width="4"></polygon>
              </g>
              <g id="Cube-125" data-name="Cube">
                <polygon points="1921.84 918.46 1816.76 979.12 1921.84 1039.79 2026.92 979.12 1921.84 918.46" fill="#2d3359" stroke="#57f2c6" stroke-miterlimit="10" stroke-width="4"></polygon>
                <polygon points="1816.76 979.12 1816.76 1100.46 1921.84 1161.13 1921.84 1039.79 1816.76 979.12" fill="#2d3359" stroke="#57f2c6" stroke-miterlimit="10" stroke-width="4"></polygon>
                <polygon points="1921.84 1161.13 2026.92 1100.46 2026.92 979.12 1921.84 1039.79 1921.84 1161.13" fill="#2d3359" stroke="#57f2c6" stroke-miterlimit="10" stroke-width="4"></polygon>
              </g>
              <g id="Cube-126" data-name="Cube">
                <polygon points="1816.84 979.46 1711.76 1040.12 1816.84 1100.79 1921.92 1040.12 1816.84 979.46" fill="#2d3359" stroke="#57f2c6" stroke-miterlimit="10" stroke-width="4"></polygon>
                <polygon points="1711.76 1040.12 1711.76 1161.46 1816.84 1222.13 1816.84 1100.79 1711.76 1040.12" fill="#2d3359" stroke="#57f2c6" stroke-miterlimit="10" stroke-width="4"></polygon>
                <polygon points="1816.84 1222.13 1921.92 1161.46 1921.92 1040.12 1816.84 1100.79 1816.84 1222.13" fill="#2d3359" stroke="#57f2c6" stroke-miterlimit="10" stroke-width="4"></polygon>
              </g>
              <g id="Cube-127" data-name="Cube">
                <polygon points="1711.84 1040.46 1606.76 1101.12 1711.84 1161.79 1816.92 1101.12 1711.84 1040.46" fill="#2d3359" stroke="#57f2c6" stroke-miterlimit="10" stroke-width="4"></polygon>
                <polygon points="1606.76 1101.12 1606.76 1222.46 1711.84 1283.13 1711.84 1161.79 1606.76 1101.12" fill="#2d3359" stroke="#57f2c6" stroke-miterlimit="10" stroke-width="4"></polygon>
                <polygon points="1711.84 1283.13 1816.92 1222.46 1816.92 1101.12 1711.84 1161.79 1711.84 1283.13" fill="#2d3359" stroke="#57f2c6" stroke-miterlimit="10" stroke-width="4"></polygon>
              </g>
              <g id="Cube-128" data-name="Cube">
                <polygon points="1606.84 1101.46 1501.76 1162.12 1606.84 1222.79 1711.92 1162.12 1606.84 1101.46" fill="#2d3359" stroke="#57f2c6" stroke-miterlimit="10" stroke-width="4"></polygon>
                <polygon points="1501.76 1162.12 1501.76 1283.46 1606.84 1344.13 1606.84 1222.79 1501.76 1162.12" fill="#2d3359" stroke="#57f2c6" stroke-miterlimit="10" stroke-width="4"></polygon>
                <polygon points="1606.84 1344.13 1711.92 1283.46 1711.92 1162.12 1606.84 1222.79 1606.84 1344.13" fill="#2d3359" stroke="#57f2c6" stroke-miterlimit="10" stroke-width="4"></polygon>
              </g>
              <g id="Cube-129" data-name="Cube">
                <polygon points="1501.84 1162.46 1396.76 1223.12 1501.84 1283.79 1606.92 1223.12 1501.84 1162.46" fill="#2d3359" stroke="#57f2c6" stroke-miterlimit="10" stroke-width="4"></polygon>
                <polygon points="1396.76 1223.12 1396.76 1344.46 1501.84 1405.13 1501.84 1283.79 1396.76 1223.12" fill="#2d3359" stroke="#57f2c6" stroke-miterlimit="10" stroke-width="4"></polygon>
                <polygon points="1501.84 1405.13 1606.92 1344.46 1606.92 1223.12 1501.84 1283.79 1501.84 1405.13" fill="#2d3359" stroke="#57f2c6" stroke-miterlimit="10" stroke-width="4"></polygon>
              </g>
              <g id="Cube-130" data-name="Cube">
                <polygon points="1396.84 1223.46 1291.76 1284.12 1396.84 1344.79 1501.92 1284.12 1396.84 1223.46" fill="#2d3359" stroke="#57f2c6" stroke-miterlimit="10" stroke-width="4"></polygon>
                <polygon points="1291.76 1284.12 1291.76 1405.46 1396.84 1466.13 1396.84 1344.79 1291.76 1284.12" fill="#2d3359" stroke="#57f2c6" stroke-miterlimit="10" stroke-width="4"></polygon>
                <polygon points="1396.84 1466.13 1501.92 1405.46 1501.92 1284.12 1396.84 1344.79 1396.84 1466.13" fill="#2d3359" stroke="#57f2c6" stroke-miterlimit="10" stroke-width="4"></polygon>
              </g>
              <g id="Cube-131" data-name="Cube">
                <polygon points="1291.84 1284.46 1186.76 1345.12 1291.84 1405.79 1396.92 1345.12 1291.84 1284.46" fill="#2d3359" stroke="#57f2c6" stroke-miterlimit="10" stroke-width="4"></polygon>
                <polygon points="1186.76 1345.12 1186.76 1466.46 1291.84 1527.13 1291.84 1405.79 1186.76 1345.12" fill="#2d3359" stroke="#57f2c6" stroke-miterlimit="10" stroke-width="4"></polygon>
                <polygon points="1291.84 1527.13 1396.92 1466.46 1396.92 1345.12 1291.84 1405.79 1291.84 1527.13" fill="#2d3359" stroke="#57f2c6" stroke-miterlimit="10" stroke-width="4"></polygon>
              </g>
              <g id="Cube-132" data-name="Cube">
                <polygon points="1186.84 1345.46 1081.76 1406.12 1186.84 1466.79 1291.92 1406.12 1186.84 1345.46" fill="#2d3359" stroke="#57f2c6" stroke-miterlimit="10" stroke-width="4"></polygon>
                <polygon points="1081.76 1406.12 1081.76 1527.46 1186.84 1588.13 1186.84 1466.79 1081.76 1406.12" fill="#2d3359" stroke="#57f2c6" stroke-miterlimit="10" stroke-width="4"></polygon>
                <polygon points="1186.84 1588.13 1291.92 1527.46 1291.92 1406.12 1186.84 1466.79 1186.84 1588.13" fill="#2d3359" stroke="#57f2c6" stroke-miterlimit="10" stroke-width="4"></polygon>
              </g>
            </g>
            <g id="CubeGroup12">
              <g id="Cube-133" data-name="Cube">
                <polygon points="2446.84 735.46 2341.76 796.12 2446.84 856.79 2551.92 796.12 2446.84 735.46" fill="#2d3359" stroke="#57f2c6" stroke-miterlimit="10" stroke-width="4"></polygon>
                <polygon points="2341.76 796.12 2341.76 917.46 2446.84 978.13 2446.84 856.79 2341.76 796.12" fill="#2d3359" stroke="#57f2c6" stroke-miterlimit="10" stroke-width="4"></polygon>
                <polygon points="2446.84 978.13 2551.92 917.46 2551.92 796.12 2446.84 856.79 2446.84 978.13" fill="#2d3359" stroke="#57f2c6" stroke-miterlimit="10" stroke-width="4"></polygon>
              </g>
              <g id="Cube-134" data-name="Cube">
                <polygon points="2341.84 796.46 2236.76 857.12 2341.84 917.79 2446.92 857.12 2341.84 796.46" fill="#2d3359" stroke="#57f2c6" stroke-miterlimit="10" stroke-width="4"></polygon>
                <polygon points="2236.76 857.12 2236.76 978.46 2341.84 1039.13 2341.84 917.79 2236.76 857.12" fill="#2d3359" stroke="#57f2c6" stroke-miterlimit="10" stroke-width="4"></polygon>
                <polygon points="2341.84 1039.13 2446.92 978.46 2446.92 857.12 2341.84 917.79 2341.84 1039.13" fill="#2d3359" stroke="#57f2c6" stroke-miterlimit="10" stroke-width="4"></polygon>
              </g>
              <g id="Cube-135" data-name="Cube">
                <polygon points="2236.84 857.46 2131.76 918.12 2236.84 978.79 2341.92 918.12 2236.84 857.46" fill="#2d3359" stroke="#57f2c6" stroke-miterlimit="10" stroke-width="4"></polygon>
                <polygon points="2131.76 918.12 2131.76 1039.46 2236.84 1100.13 2236.84 978.79 2131.76 918.12" fill="#2d3359" stroke="#57f2c6" stroke-miterlimit="10" stroke-width="4"></polygon>
                <polygon points="2236.84 1100.13 2341.92 1039.46 2341.92 918.12 2236.84 978.79 2236.84 1100.13" fill="#2d3359" stroke="#57f2c6" stroke-miterlimit="10" stroke-width="4"></polygon>
              </g>
              <g id="Cube-136" data-name="Cube">
                <polygon points="2131.84 918.46 2026.76 979.12 2131.84 1039.79 2236.92 979.12 2131.84 918.46" fill="#2d3359" stroke="#57f2c6" stroke-miterlimit="10" stroke-width="4"></polygon>
                <polygon points="2026.76 979.12 2026.76 1100.46 2131.84 1161.13 2131.84 1039.79 2026.76 979.12" fill="#2d3359" stroke="#57f2c6" stroke-miterlimit="10" stroke-width="4"></polygon>
                <polygon points="2131.84 1161.13 2236.92 1100.46 2236.92 979.12 2131.84 1039.79 2131.84 1161.13" fill="#2d3359" stroke="#57f2c6" stroke-miterlimit="10" stroke-width="4"></polygon>
              </g>
              <g id="Cube-137" data-name="Cube">
                <polygon points="2026.84 979.46 1921.76 1040.12 2026.84 1100.79 2131.92 1040.12 2026.84 979.46" fill="#2d3359" stroke="#57f2c6" stroke-miterlimit="10" stroke-width="4"></polygon>
                <polygon points="1921.76 1040.12 1921.76 1161.46 2026.84 1222.13 2026.84 1100.79 1921.76 1040.12" fill="#2d3359" stroke="#57f2c6" stroke-miterlimit="10" stroke-width="4"></polygon>
                <polygon points="2026.84 1222.13 2131.92 1161.46 2131.92 1040.12 2026.84 1100.79 2026.84 1222.13" fill="#2d3359" stroke="#57f2c6" stroke-miterlimit="10" stroke-width="4"></polygon>
              </g>
              <g id="Cube-138" data-name="Cube">
                <polygon points="1921.84 1040.46 1816.76 1101.12 1921.84 1161.79 2026.92 1101.12 1921.84 1040.46" fill="#2d3359" stroke="#57f2c6" stroke-miterlimit="10" stroke-width="4"></polygon>
                <polygon points="1816.76 1101.12 1816.76 1222.46 1921.84 1283.13 1921.84 1161.79 1816.76 1101.12" fill="#2d3359" stroke="#57f2c6" stroke-miterlimit="10" stroke-width="4"></polygon>
                <polygon points="1921.84 1283.13 2026.92 1222.46 2026.92 1101.12 1921.84 1161.79 1921.84 1283.13" fill="#2d3359" stroke="#57f2c6" stroke-miterlimit="10" stroke-width="4"></polygon>
              </g>
              <g id="Cube-139" data-name="Cube">
                <polygon points="1816.84 1101.46 1711.76 1162.12 1816.84 1222.79 1921.92 1162.12 1816.84 1101.46" fill="#2d3359" stroke="#57f2c6" stroke-miterlimit="10" stroke-width="4"></polygon>
                <polygon points="1711.76 1162.12 1711.76 1283.46 1816.84 1344.13 1816.84 1222.79 1711.76 1162.12" fill="#2d3359" stroke="#57f2c6" stroke-miterlimit="10" stroke-width="4"></polygon>
                <polygon points="1816.84 1344.13 1921.92 1283.46 1921.92 1162.12 1816.84 1222.79 1816.84 1344.13" fill="#2d3359" stroke="#57f2c6" stroke-miterlimit="10" stroke-width="4"></polygon>
              </g>
              <g id="Cube-140" data-name="Cube">
                <polygon points="1711.84 1162.46 1606.76 1223.12 1711.84 1283.79 1816.92 1223.12 1711.84 1162.46" fill="#2d3359" stroke="#57f2c6" stroke-miterlimit="10" stroke-width="4"></polygon>
                <polygon points="1606.76 1223.12 1606.76 1344.46 1711.84 1405.13 1711.84 1283.79 1606.76 1223.12" fill="#2d3359" stroke="#57f2c6" stroke-miterlimit="10" stroke-width="4"></polygon>
                <polygon points="1711.84 1405.13 1816.92 1344.46 1816.92 1223.12 1711.84 1283.79 1711.84 1405.13" fill="#2d3359" stroke="#57f2c6" stroke-miterlimit="10" stroke-width="4"></polygon>
              </g>
              <g id="Cube-141" data-name="Cube">
                <polygon points="1606.84 1223.46 1501.76 1284.12 1606.84 1344.79 1711.92 1284.12 1606.84 1223.46" fill="#2d3359" stroke="#57f2c6" stroke-miterlimit="10" stroke-width="4"></polygon>
                <polygon points="1501.76 1284.12 1501.76 1405.46 1606.84 1466.13 1606.84 1344.79 1501.76 1284.12" fill="#2d3359" stroke="#57f2c6" stroke-miterlimit="10" stroke-width="4"></polygon>
                <polygon points="1606.84 1466.13 1711.92 1405.46 1711.92 1284.12 1606.84 1344.79 1606.84 1466.13" fill="#2d3359" stroke="#57f2c6" stroke-miterlimit="10" stroke-width="4"></polygon>
              </g>
              <g id="Cube-142" data-name="Cube">
                <polygon points="1501.84 1284.46 1396.76 1345.12 1501.84 1405.79 1606.92 1345.12 1501.84 1284.46" fill="#2d3359" stroke="#57f2c6" stroke-miterlimit="10" stroke-width="4"></polygon>
                <polygon points="1396.76 1345.12 1396.76 1466.46 1501.84 1527.13 1501.84 1405.79 1396.76 1345.12" fill="#2d3359" stroke="#57f2c6" stroke-miterlimit="10" stroke-width="4"></polygon>
                <polygon points="1501.84 1527.13 1606.92 1466.46 1606.92 1345.12 1501.84 1405.79 1501.84 1527.13" fill="#2d3359" stroke="#57f2c6" stroke-miterlimit="10" stroke-width="4"></polygon>
              </g>
              <g id="Cube-143" data-name="Cube">
                <polygon points="1396.84 1345.46 1291.76 1406.12 1396.84 1466.79 1501.92 1406.12 1396.84 1345.46" fill="#2d3359" stroke="#57f2c6" stroke-miterlimit="10" stroke-width="4"></polygon>
                <polygon points="1291.76 1406.12 1291.76 1527.46 1396.84 1588.13 1396.84 1466.79 1291.76 1406.12" fill="#2d3359" stroke="#57f2c6" stroke-miterlimit="10" stroke-width="4"></polygon>
                <polygon points="1396.84 1588.13 1501.92 1527.46 1501.92 1406.12 1396.84 1466.79 1396.84 1588.13" fill="#2d3359" stroke="#57f2c6" stroke-miterlimit="10" stroke-width="4"></polygon>
              </g>
              <g id="Cube-144" data-name="Cube">
                <polygon points="1291.84 1406.46 1186.76 1467.12 1291.84 1527.79 1396.92 1467.12 1291.84 1406.46" fill="#2d3359" stroke="#57f2c6" stroke-miterlimit="10" stroke-width="4"></polygon>
                <polygon points="1186.76 1467.12 1186.76 1588.46 1291.84 1649.13 1291.84 1527.79 1186.76 1467.12" fill="#2d3359" stroke="#57f2c6" stroke-miterlimit="10" stroke-width="4"></polygon>
                <polygon points="1291.84 1649.13 1396.92 1588.46 1396.92 1467.12 1291.84 1527.79 1291.84 1649.13" fill="#2d3359" stroke="#57f2c6" stroke-miterlimit="10" stroke-width="4"></polygon>
              </g>
            </g>
            <g id="CubeGroup13">
              <g id="Cube-145" data-name="Cube">
                <polygon points="2551.84 796.46 2446.76 857.12 2551.84 917.79 2656.92 857.12 2551.84 796.46" fill="#2d3359" stroke="#57f2c6" stroke-miterlimit="10" stroke-width="4"></polygon>
                <polygon points="2446.76 857.12 2446.76 978.46 2551.84 1039.13 2551.84 917.79 2446.76 857.12" fill="#2d3359" stroke="#57f2c6" stroke-miterlimit="10" stroke-width="4"></polygon>
                <polygon points="2551.84 1039.13 2656.92 978.46 2656.92 857.12 2551.84 917.79 2551.84 1039.13" fill="#2d3359" stroke="#57f2c6" stroke-miterlimit="10" stroke-width="4"></polygon>
              </g>
              <g id="Cube-146" data-name="Cube">
                <polygon points="2446.84 857.46 2341.76 918.12 2446.84 978.79 2551.92 918.12 2446.84 857.46" fill="#2d3359" stroke="#57f2c6" stroke-miterlimit="10" stroke-width="4"></polygon>
                <polygon points="2341.76 918.12 2341.76 1039.46 2446.84 1100.13 2446.84 978.79 2341.76 918.12" fill="#2d3359" stroke="#57f2c6" stroke-miterlimit="10" stroke-width="4"></polygon>
                <polygon points="2446.84 1100.13 2551.92 1039.46 2551.92 918.12 2446.84 978.79 2446.84 1100.13" fill="#2d3359" stroke="#57f2c6" stroke-miterlimit="10" stroke-width="4"></polygon>
              </g>
              <g id="Cube-147" data-name="Cube">
                <polygon points="2341.84 918.46 2236.76 979.12 2341.84 1039.79 2446.92 979.12 2341.84 918.46" fill="#2d3359" stroke="#57f2c6" stroke-miterlimit="10" stroke-width="4"></polygon>
                <polygon points="2236.76 979.12 2236.76 1100.46 2341.84 1161.13 2341.84 1039.79 2236.76 979.12" fill="#2d3359" stroke="#57f2c6" stroke-miterlimit="10" stroke-width="4"></polygon>
                <polygon points="2341.84 1161.13 2446.92 1100.46 2446.92 979.12 2341.84 1039.79 2341.84 1161.13" fill="#2d3359" stroke="#57f2c6" stroke-miterlimit="10" stroke-width="4"></polygon>
              </g>
              <g id="Cube-148" data-name="Cube">
                <polygon points="2236.84 979.46 2131.76 1040.12 2236.84 1100.79 2341.92 1040.12 2236.84 979.46" fill="#2d3359" stroke="#57f2c6" stroke-miterlimit="10" stroke-width="4"></polygon>
                <polygon points="2131.76 1040.12 2131.76 1161.46 2236.84 1222.13 2236.84 1100.79 2131.76 1040.12" fill="#2d3359" stroke="#57f2c6" stroke-miterlimit="10" stroke-width="4"></polygon>
                <polygon points="2236.84 1222.13 2341.92 1161.46 2341.92 1040.12 2236.84 1100.79 2236.84 1222.13" fill="#2d3359" stroke="#57f2c6" stroke-miterlimit="10" stroke-width="4"></polygon>
              </g>
              <g id="Cube-149" data-name="Cube">
                <polygon points="2131.84 1040.46 2026.76 1101.12 2131.84 1161.79 2236.92 1101.12 2131.84 1040.46" fill="#2d3359" stroke="#57f2c6" stroke-miterlimit="10" stroke-width="4"></polygon>
                <polygon points="2026.76 1101.12 2026.76 1222.46 2131.84 1283.13 2131.84 1161.79 2026.76 1101.12" fill="#2d3359" stroke="#57f2c6" stroke-miterlimit="10" stroke-width="4"></polygon>
                <polygon points="2131.84 1283.13 2236.92 1222.46 2236.92 1101.12 2131.84 1161.79 2131.84 1283.13" fill="#2d3359" stroke="#57f2c6" stroke-miterlimit="10" stroke-width="4"></polygon>
              </g>
              <g id="Cube-150" data-name="Cube">
                <polygon points="2026.84 1101.46 1921.76 1162.12 2026.84 1222.79 2131.92 1162.12 2026.84 1101.46" fill="#2d3359" stroke="#57f2c6" stroke-miterlimit="10" stroke-width="4"></polygon>
                <polygon points="1921.76 1162.12 1921.76 1283.46 2026.84 1344.13 2026.84 1222.79 1921.76 1162.12" fill="#2d3359" stroke="#57f2c6" stroke-miterlimit="10" stroke-width="4"></polygon>
                <polygon points="2026.84 1344.13 2131.92 1283.46 2131.92 1162.12 2026.84 1222.79 2026.84 1344.13" fill="#2d3359" stroke="#57f2c6" stroke-miterlimit="10" stroke-width="4"></polygon>
              </g>
              <g id="Cube-151" data-name="Cube">
                <polygon points="1921.84 1162.46 1816.76 1223.12 1921.84 1283.79 2026.92 1223.12 1921.84 1162.46" fill="#2d3359" stroke="#57f2c6" stroke-miterlimit="10" stroke-width="4"></polygon>
                <polygon points="1816.76 1223.12 1816.76 1344.46 1921.84 1405.13 1921.84 1283.79 1816.76 1223.12" fill="#2d3359" stroke="#57f2c6" stroke-miterlimit="10" stroke-width="4"></polygon>
                <polygon points="1921.84 1405.13 2026.92 1344.46 2026.92 1223.12 1921.84 1283.79 1921.84 1405.13" fill="#2d3359" stroke="#57f2c6" stroke-miterlimit="10" stroke-width="4"></polygon>
              </g>
              <g id="Cube-152" data-name="Cube">
                <polygon points="1816.84 1223.46 1711.76 1284.12 1816.84 1344.79 1921.92 1284.12 1816.84 1223.46" fill="#2d3359" stroke="#57f2c6" stroke-miterlimit="10" stroke-width="4"></polygon>
                <polygon points="1711.76 1284.12 1711.76 1405.46 1816.84 1466.13 1816.84 1344.79 1711.76 1284.12" fill="#2d3359" stroke="#57f2c6" stroke-miterlimit="10" stroke-width="4"></polygon>
                <polygon points="1816.84 1466.13 1921.92 1405.46 1921.92 1284.12 1816.84 1344.79 1816.84 1466.13" fill="#2d3359" stroke="#57f2c6" stroke-miterlimit="10" stroke-width="4"></polygon>
              </g>
              <g id="Cube-153" data-name="Cube">
                <polygon points="1711.84 1284.46 1606.76 1345.12 1711.84 1405.79 1816.92 1345.12 1711.84 1284.46" fill="#2d3359" stroke="#57f2c6" stroke-miterlimit="10" stroke-width="4"></polygon>
                <polygon points="1606.76 1345.12 1606.76 1466.46 1711.84 1527.13 1711.84 1405.79 1606.76 1345.12" fill="#2d3359" stroke="#57f2c6" stroke-miterlimit="10" stroke-width="4"></polygon>
                <polygon points="1711.84 1527.13 1816.92 1466.46 1816.92 1345.12 1711.84 1405.79 1711.84 1527.13" fill="#2d3359" stroke="#57f2c6" stroke-miterlimit="10" stroke-width="4"></polygon>
              </g>
              <g id="Cube-154" data-name="Cube">
                <polygon points="1606.84 1345.46 1501.76 1406.12 1606.84 1466.79 1711.92 1406.12 1606.84 1345.46" fill="#2d3359" stroke="#57f2c6" stroke-miterlimit="10" stroke-width="4"></polygon>
                <polygon points="1501.76 1406.12 1501.76 1527.46 1606.84 1588.13 1606.84 1466.79 1501.76 1406.12" fill="#2d3359" stroke="#57f2c6" stroke-miterlimit="10" stroke-width="4"></polygon>
                <polygon points="1606.84 1588.13 1711.92 1527.46 1711.92 1406.12 1606.84 1466.79 1606.84 1588.13" fill="#2d3359" stroke="#57f2c6" stroke-miterlimit="10" stroke-width="4"></polygon>
              </g>
              <g id="Cube-155" data-name="Cube">
                <polygon points="1501.84 1406.46 1396.76 1467.12 1501.84 1527.79 1606.92 1467.12 1501.84 1406.46" fill="#2d3359" stroke="#57f2c6" stroke-miterlimit="10" stroke-width="4"></polygon>
                <polygon points="1396.76 1467.12 1396.76 1588.46 1501.84 1649.13 1501.84 1527.79 1396.76 1467.12" fill="#2d3359" stroke="#57f2c6" stroke-miterlimit="10" stroke-width="4"></polygon>
                <polygon points="1501.84 1649.13 1606.92 1588.46 1606.92 1467.12 1501.84 1527.79 1501.84 1649.13" fill="#2d3359" stroke="#57f2c6" stroke-miterlimit="10" stroke-width="4"></polygon>
              </g>
              <g id="Cube-156" data-name="Cube">
                <polygon points="1396.84 1467.46 1291.76 1528.12 1396.84 1588.79 1501.92 1528.12 1396.84 1467.46" fill="#2d3359" stroke="#57f2c6" stroke-miterlimit="10" stroke-width="4"></polygon>
                <polygon points="1291.76 1528.12 1291.76 1649.46 1396.84 1710.13 1396.84 1588.79 1291.76 1528.12" fill="#2d3359" stroke="#57f2c6" stroke-miterlimit="10" stroke-width="4"></polygon>
                <polygon points="1396.84 1710.13 1501.92 1649.46 1501.92 1528.12 1396.84 1588.79 1396.84 1710.13" fill="#2d3359" stroke="#57f2c6" stroke-miterlimit="10" stroke-width="4"></polygon>
              </g>
            </g>
            <g id="CubeGroup14">
              <g id="Cube-157" data-name="Cube">
                <polygon points="2656.84 857.46 2551.76 918.12 2656.84 978.79 2761.92 918.12 2656.84 857.46" fill="#2d3359" stroke="#57f2c6" stroke-miterlimit="10" stroke-width="4"></polygon>
                <polygon points="2551.76 918.12 2551.76 1039.46 2656.84 1100.13 2656.84 978.79 2551.76 918.12" fill="#2d3359" stroke="#57f2c6" stroke-miterlimit="10" stroke-width="4"></polygon>
                <polygon points="2656.84 1100.13 2761.92 1039.46 2761.92 918.12 2656.84 978.79 2656.84 1100.13" fill="#2d3359" stroke="#57f2c6" stroke-miterlimit="10" stroke-width="4"></polygon>
              </g>
              <g id="Cube-158" data-name="Cube">
                <polygon points="2551.84 918.46 2446.76 979.12 2551.84 1039.79 2656.92 979.12 2551.84 918.46" fill="#2d3359" stroke="#57f2c6" stroke-miterlimit="10" stroke-width="4"></polygon>
                <polygon points="2446.76 979.12 2446.76 1100.46 2551.84 1161.13 2551.84 1039.79 2446.76 979.12" fill="#2d3359" stroke="#57f2c6" stroke-miterlimit="10" stroke-width="4"></polygon>
                <polygon points="2551.84 1161.13 2656.92 1100.46 2656.92 979.12 2551.84 1039.79 2551.84 1161.13" fill="#2d3359" stroke="#57f2c6" stroke-miterlimit="10" stroke-width="4"></polygon>
              </g>
              <g id="Cube-159" data-name="Cube">
                <polygon points="2446.84 979.46 2341.76 1040.12 2446.84 1100.79 2551.92 1040.12 2446.84 979.46" fill="#2d3359" stroke="#57f2c6" stroke-miterlimit="10" stroke-width="4"></polygon>
                <polygon points="2341.76 1040.12 2341.76 1161.46 2446.84 1222.13 2446.84 1100.79 2341.76 1040.12" fill="#2d3359" stroke="#57f2c6" stroke-miterlimit="10" stroke-width="4"></polygon>
                <polygon points="2446.84 1222.13 2551.92 1161.46 2551.92 1040.12 2446.84 1100.79 2446.84 1222.13" fill="#2d3359" stroke="#57f2c6" stroke-miterlimit="10" stroke-width="4"></polygon>
              </g>
              <g id="Cube-160" data-name="Cube">
                <polygon points="2341.84 1040.46 2236.76 1101.12 2341.84 1161.79 2446.92 1101.12 2341.84 1040.46" fill="#2d3359" stroke="#57f2c6" stroke-miterlimit="10" stroke-width="4"></polygon>
                <polygon points="2236.76 1101.12 2236.76 1222.46 2341.84 1283.13 2341.84 1161.79 2236.76 1101.12" fill="#2d3359" stroke="#57f2c6" stroke-miterlimit="10" stroke-width="4"></polygon>
                <polygon points="2341.84 1283.13 2446.92 1222.46 2446.92 1101.12 2341.84 1161.79 2341.84 1283.13" fill="#2d3359" stroke="#57f2c6" stroke-miterlimit="10" stroke-width="4"></polygon>
              </g>
              <g id="Cube-161" data-name="Cube">
                <polygon points="2236.84 1101.46 2131.76 1162.12 2236.84 1222.79 2341.92 1162.12 2236.84 1101.46" fill="#2d3359" stroke="#57f2c6" stroke-miterlimit="10" stroke-width="4"></polygon>
                <polygon points="2131.76 1162.12 2131.76 1283.46 2236.84 1344.13 2236.84 1222.79 2131.76 1162.12" fill="#2d3359" stroke="#57f2c6" stroke-miterlimit="10" stroke-width="4"></polygon>
                <polygon points="2236.84 1344.13 2341.92 1283.46 2341.92 1162.12 2236.84 1222.79 2236.84 1344.13" fill="#2d3359" stroke="#57f2c6" stroke-miterlimit="10" stroke-width="4"></polygon>
              </g>
              <g id="Cube-162" data-name="Cube">
                <polygon points="2131.84 1162.46 2026.76 1223.12 2131.84 1283.79 2236.92 1223.12 2131.84 1162.46" fill="#2d3359" stroke="#57f2c6" stroke-miterlimit="10" stroke-width="4"></polygon>
                <polygon points="2026.76 1223.12 2026.76 1344.46 2131.84 1405.13 2131.84 1283.79 2026.76 1223.12" fill="#2d3359" stroke="#57f2c6" stroke-miterlimit="10" stroke-width="4"></polygon>
                <polygon points="2131.84 1405.13 2236.92 1344.46 2236.92 1223.12 2131.84 1283.79 2131.84 1405.13" fill="#2d3359" stroke="#57f2c6" stroke-miterlimit="10" stroke-width="4"></polygon>
              </g>
              <g id="Cube-163" data-name="Cube">
                <polygon points="2026.84 1223.46 1921.76 1284.12 2026.84 1344.79 2131.92 1284.12 2026.84 1223.46" fill="#2d3359" stroke="#57f2c6" stroke-miterlimit="10" stroke-width="4"></polygon>
                <polygon points="1921.76 1284.12 1921.76 1405.46 2026.84 1466.13 2026.84 1344.79 1921.76 1284.12" fill="#2d3359" stroke="#57f2c6" stroke-miterlimit="10" stroke-width="4"></polygon>
                <polygon points="2026.84 1466.13 2131.92 1405.46 2131.92 1284.12 2026.84 1344.79 2026.84 1466.13" fill="#2d3359" stroke="#57f2c6" stroke-miterlimit="10" stroke-width="4"></polygon>
              </g>
              <g id="Cube-164" data-name="Cube">
                <polygon points="1921.84 1284.46 1816.76 1345.12 1921.84 1405.79 2026.92 1345.12 1921.84 1284.46" fill="#2d3359" stroke="#57f2c6" stroke-miterlimit="10" stroke-width="4"></polygon>
                <polygon points="1816.76 1345.12 1816.76 1466.46 1921.84 1527.13 1921.84 1405.79 1816.76 1345.12" fill="#2d3359" stroke="#57f2c6" stroke-miterlimit="10" stroke-width="4"></polygon>
                <polygon points="1921.84 1527.13 2026.92 1466.46 2026.92 1345.12 1921.84 1405.79 1921.84 1527.13" fill="#2d3359" stroke="#57f2c6" stroke-miterlimit="10" stroke-width="4"></polygon>
              </g>
              <g id="Cube-165" data-name="Cube">
                <polygon points="1816.84 1345.46 1711.76 1406.12 1816.84 1466.79 1921.92 1406.12 1816.84 1345.46" fill="#2d3359" stroke="#57f2c6" stroke-miterlimit="10" stroke-width="4"></polygon>
                <polygon points="1711.76 1406.12 1711.76 1527.46 1816.84 1588.13 1816.84 1466.79 1711.76 1406.12" fill="#2d3359" stroke="#57f2c6" stroke-miterlimit="10" stroke-width="4"></polygon>
                <polygon points="1816.84 1588.13 1921.92 1527.46 1921.92 1406.12 1816.84 1466.79 1816.84 1588.13" fill="#2d3359" stroke="#57f2c6" stroke-miterlimit="10" stroke-width="4"></polygon>
              </g>
              <g id="Cube-166" data-name="Cube">
                <polygon points="1711.84 1406.46 1606.76 1467.12 1711.84 1527.79 1816.92 1467.12 1711.84 1406.46" fill="#2d3359" stroke="#57f2c6" stroke-miterlimit="10" stroke-width="4"></polygon>
                <polygon points="1606.76 1467.12 1606.76 1588.46 1711.84 1649.13 1711.84 1527.79 1606.76 1467.12" fill="#2d3359" stroke="#57f2c6" stroke-miterlimit="10" stroke-width="4"></polygon>
                <polygon points="1711.84 1649.13 1816.92 1588.46 1816.92 1467.12 1711.84 1527.79 1711.84 1649.13" fill="#2d3359" stroke="#57f2c6" stroke-miterlimit="10" stroke-width="4"></polygon>
              </g>
              <g id="Cube-167" data-name="Cube">
                <polygon points="1606.84 1467.46 1501.76 1528.12 1606.84 1588.79 1711.92 1528.12 1606.84 1467.46" fill="#2d3359" stroke="#57f2c6" stroke-miterlimit="10" stroke-width="4"></polygon>
                <polygon points="1501.76 1528.12 1501.76 1649.46 1606.84 1710.13 1606.84 1588.79 1501.76 1528.12" fill="#2d3359" stroke="#57f2c6" stroke-miterlimit="10" stroke-width="4"></polygon>
                <polygon points="1606.84 1710.13 1711.92 1649.46 1711.92 1528.12 1606.84 1588.79 1606.84 1710.13" fill="#2d3359" stroke="#57f2c6" stroke-miterlimit="10" stroke-width="4"></polygon>
              </g>
              <g id="Cube-168" data-name="Cube">
                <polygon points="1501.84 1528.46 1396.76 1589.12 1501.84 1649.79 1606.92 1589.12 1501.84 1528.46" fill="#2d3359" stroke="#57f2c6" stroke-miterlimit="10" stroke-width="4"></polygon>
                <polygon points="1396.76 1589.12 1396.76 1710.46 1501.84 1771.13 1501.84 1649.79 1396.76 1589.12" fill="#2d3359" stroke="#57f2c6" stroke-miterlimit="10" stroke-width="4"></polygon>
                <polygon points="1501.84 1771.13 1606.92 1710.46 1606.92 1589.12 1501.84 1649.79 1501.84 1771.13" fill="#2d3359" stroke="#57f2c6" stroke-miterlimit="10" stroke-width="4"></polygon>
              </g>
            </g>
            <g id="CubeGroup15">
              <g id="Cube-169" data-name="Cube">
                <polygon points="2761.84 918.46 2656.76 979.12 2761.84 1039.79 2866.92 979.12 2761.84 918.46" fill="#2d3359" stroke="#57f2c6" stroke-miterlimit="10" stroke-width="4"></polygon>
                <polygon points="2656.76 979.12 2656.76 1100.46 2761.84 1161.13 2761.84 1039.79 2656.76 979.12" fill="#2d3359" stroke="#57f2c6" stroke-miterlimit="10" stroke-width="4"></polygon>
                <polygon points="2761.84 1161.13 2866.92 1100.46 2866.92 979.12 2761.84 1039.79 2761.84 1161.13" fill="#2d3359" stroke="#57f2c6" stroke-miterlimit="10" stroke-width="4"></polygon>
              </g>
              <g id="Cube-170" data-name="Cube">
                <polygon points="2656.84 979.46 2551.76 1040.12 2656.84 1100.79 2761.92 1040.12 2656.84 979.46" fill="#2d3359" stroke="#57f2c6" stroke-miterlimit="10" stroke-width="4"></polygon>
                <polygon points="2551.76 1040.12 2551.76 1161.46 2656.84 1222.13 2656.84 1100.79 2551.76 1040.12" fill="#2d3359" stroke="#57f2c6" stroke-miterlimit="10" stroke-width="4"></polygon>
                <polygon points="2656.84 1222.13 2761.92 1161.46 2761.92 1040.12 2656.84 1100.79 2656.84 1222.13" fill="#2d3359" stroke="#57f2c6" stroke-miterlimit="10" stroke-width="4"></polygon>
              </g>
              <g id="Cube-171" data-name="Cube">
                <polygon points="2551.84 1040.46 2446.76 1101.12 2551.84 1161.79 2656.92 1101.12 2551.84 1040.46" fill="#2d3359" stroke="#57f2c6" stroke-miterlimit="10" stroke-width="4"></polygon>
                <polygon points="2446.76 1101.12 2446.76 1222.46 2551.84 1283.13 2551.84 1161.79 2446.76 1101.12" fill="#2d3359" stroke="#57f2c6" stroke-miterlimit="10" stroke-width="4"></polygon>
                <polygon points="2551.84 1283.13 2656.92 1222.46 2656.92 1101.12 2551.84 1161.79 2551.84 1283.13" fill="#2d3359" stroke="#57f2c6" stroke-miterlimit="10" stroke-width="4"></polygon>
              </g>
              <g id="Cube-172" data-name="Cube">
                <polygon points="2446.84 1101.46 2341.76 1162.12 2446.84 1222.79 2551.92 1162.12 2446.84 1101.46" fill="#2d3359" stroke="#57f2c6" stroke-miterlimit="10" stroke-width="4"></polygon>
                <polygon points="2341.76 1162.12 2341.76 1283.46 2446.84 1344.13 2446.84 1222.79 2341.76 1162.12" fill="#2d3359" stroke="#57f2c6" stroke-miterlimit="10" stroke-width="4"></polygon>
                <polygon points="2446.84 1344.13 2551.92 1283.46 2551.92 1162.12 2446.84 1222.79 2446.84 1344.13" fill="#2d3359" stroke="#57f2c6" stroke-miterlimit="10" stroke-width="4"></polygon>
              </g>
              <g id="Cube-173" data-name="Cube">
                <polygon points="2341.84 1162.46 2236.76 1223.12 2341.84 1283.79 2446.92 1223.12 2341.84 1162.46" fill="#2d3359" stroke="#57f2c6" stroke-miterlimit="10" stroke-width="4"></polygon>
                <polygon points="2236.76 1223.12 2236.76 1344.46 2341.84 1405.13 2341.84 1283.79 2236.76 1223.12" fill="#2d3359" stroke="#57f2c6" stroke-miterlimit="10" stroke-width="4"></polygon>
                <polygon points="2341.84 1405.13 2446.92 1344.46 2446.92 1223.12 2341.84 1283.79 2341.84 1405.13" fill="#2d3359" stroke="#57f2c6" stroke-miterlimit="10" stroke-width="4"></polygon>
              </g>
              <g id="Cube-174" data-name="Cube">
                <polygon points="2236.84 1223.46 2131.76 1284.12 2236.84 1344.79 2341.92 1284.12 2236.84 1223.46" fill="#2d3359" stroke="#57f2c6" stroke-miterlimit="10" stroke-width="4"></polygon>
                <polygon points="2131.76 1284.12 2131.76 1405.46 2236.84 1466.13 2236.84 1344.79 2131.76 1284.12" fill="#2d3359" stroke="#57f2c6" stroke-miterlimit="10" stroke-width="4"></polygon>
                <polygon points="2236.84 1466.13 2341.92 1405.46 2341.92 1284.12 2236.84 1344.79 2236.84 1466.13" fill="#2d3359" stroke="#57f2c6" stroke-miterlimit="10" stroke-width="4"></polygon>
              </g>
              <g id="Cube-175" data-name="Cube">
                <polygon points="2131.84 1284.46 2026.76 1345.12 2131.84 1405.79 2236.92 1345.12 2131.84 1284.46" fill="#2d3359" stroke="#57f2c6" stroke-miterlimit="10" stroke-width="4"></polygon>
                <polygon points="2026.76 1345.12 2026.76 1466.46 2131.84 1527.13 2131.84 1405.79 2026.76 1345.12" fill="#2d3359" stroke="#57f2c6" stroke-miterlimit="10" stroke-width="4"></polygon>
                <polygon points="2131.84 1527.13 2236.92 1466.46 2236.92 1345.12 2131.84 1405.79 2131.84 1527.13" fill="#2d3359" stroke="#57f2c6" stroke-miterlimit="10" stroke-width="4"></polygon>
              </g>
              <g id="Cube-176" data-name="Cube">
                <polygon points="2026.84 1345.46 1921.76 1406.12 2026.84 1466.79 2131.92 1406.12 2026.84 1345.46" fill="#2d3359" stroke="#57f2c6" stroke-miterlimit="10" stroke-width="4"></polygon>
                <polygon points="1921.76 1406.12 1921.76 1527.46 2026.84 1588.13 2026.84 1466.79 1921.76 1406.12" fill="#2d3359" stroke="#57f2c6" stroke-miterlimit="10" stroke-width="4"></polygon>
                <polygon points="2026.84 1588.13 2131.92 1527.46 2131.92 1406.12 2026.84 1466.79 2026.84 1588.13" fill="#2d3359" stroke="#57f2c6" stroke-miterlimit="10" stroke-width="4"></polygon>
              </g>
              <g id="Cube-177" data-name="Cube">
                <polygon points="1921.84 1406.46 1816.76 1467.12 1921.84 1527.79 2026.92 1467.12 1921.84 1406.46" fill="#2d3359" stroke="#57f2c6" stroke-miterlimit="10" stroke-width="4"></polygon>
                <polygon points="1816.76 1467.12 1816.76 1588.46 1921.84 1649.13 1921.84 1527.79 1816.76 1467.12" fill="#2d3359" stroke="#57f2c6" stroke-miterlimit="10" stroke-width="4"></polygon>
                <polygon points="1921.84 1649.13 2026.92 1588.46 2026.92 1467.12 1921.84 1527.79 1921.84 1649.13" fill="#2d3359" stroke="#57f2c6" stroke-miterlimit="10" stroke-width="4"></polygon>
              </g>
              <g id="Cube-178" data-name="Cube">
                <polygon points="1816.84 1467.46 1711.76 1528.12 1816.84 1588.79 1921.92 1528.12 1816.84 1467.46" fill="#2d3359" stroke="#57f2c6" stroke-miterlimit="10" stroke-width="4"></polygon>
                <polygon points="1711.76 1528.12 1711.76 1649.46 1816.84 1710.13 1816.84 1588.79 1711.76 1528.12" fill="#2d3359" stroke="#57f2c6" stroke-miterlimit="10" stroke-width="4"></polygon>
                <polygon points="1816.84 1710.13 1921.92 1649.46 1921.92 1528.12 1816.84 1588.79 1816.84 1710.13" fill="#2d3359" stroke="#57f2c6" stroke-miterlimit="10" stroke-width="4"></polygon>
              </g>
              <g id="Cube-179" data-name="Cube">
                <polygon points="1711.84 1528.46 1606.76 1589.12 1711.84 1649.79 1816.92 1589.12 1711.84 1528.46" fill="#2d3359" stroke="#57f2c6" stroke-miterlimit="10" stroke-width="4"></polygon>
                <polygon points="1606.76 1589.12 1606.76 1710.46 1711.84 1771.13 1711.84 1649.79 1606.76 1589.12" fill="#2d3359" stroke="#57f2c6" stroke-miterlimit="10" stroke-width="4"></polygon>
                <polygon points="1711.84 1771.13 1816.92 1710.46 1816.92 1589.12 1711.84 1649.79 1711.84 1771.13" fill="#2d3359" stroke="#57f2c6" stroke-miterlimit="10" stroke-width="4"></polygon>
              </g>
              <g id="Cube-180" data-name="Cube">
                <polygon points="1606.84 1589.46 1501.76 1650.12 1606.84 1710.79 1711.92 1650.12 1606.84 1589.46" fill="#2d3359" stroke="#57f2c6" stroke-miterlimit="10" stroke-width="4"></polygon>
                <polygon points="1501.76 1650.12 1501.76 1771.46 1606.84 1832.13 1606.84 1710.79 1501.76 1650.12" fill="#2d3359" stroke="#57f2c6" stroke-miterlimit="10" stroke-width="4"></polygon>
                <polygon points="1606.84 1832.13 1711.92 1771.46 1711.92 1650.12 1606.84 1710.79 1606.84 1832.13" fill="#2d3359" stroke="#57f2c6" stroke-miterlimit="10" stroke-width="4"></polygon>
              </g>
            </g>
            <g id="CubeGroup16">
              <g id="Cube-181" data-name="Cube">
                <polygon points="2866.84 979.46 2761.76 1040.12 2866.84 1100.79 2971.92 1040.12 2866.84 979.46" fill="#2d3359" stroke="#57f2c6" stroke-miterlimit="10" stroke-width="4"></polygon>
                <polygon points="2761.76 1040.12 2761.76 1161.46 2866.84 1222.13 2866.84 1100.79 2761.76 1040.12" fill="#2d3359" stroke="#57f2c6" stroke-miterlimit="10" stroke-width="4"></polygon>
                <polygon points="2866.84 1222.13 2971.92 1161.46 2971.92 1040.12 2866.84 1100.79 2866.84 1222.13" fill="#2d3359" stroke="#57f2c6" stroke-miterlimit="10" stroke-width="4"></polygon>
              </g>
              <g id="Cube-182" data-name="Cube">
                <polygon points="2761.84 1040.46 2656.76 1101.12 2761.84 1161.79 2866.92 1101.12 2761.84 1040.46" fill="#2d3359" stroke="#57f2c6" stroke-miterlimit="10" stroke-width="4"></polygon>
                <polygon points="2656.76 1101.12 2656.76 1222.46 2761.84 1283.13 2761.84 1161.79 2656.76 1101.12" fill="#2d3359" stroke="#57f2c6" stroke-miterlimit="10" stroke-width="4"></polygon>
                <polygon points="2761.84 1283.13 2866.92 1222.46 2866.92 1101.12 2761.84 1161.79 2761.84 1283.13" fill="#2d3359" stroke="#57f2c6" stroke-miterlimit="10" stroke-width="4"></polygon>
              </g>
              <g id="Cube-183" data-name="Cube">
                <polygon points="2656.84 1101.46 2551.76 1162.12 2656.84 1222.79 2761.92 1162.12 2656.84 1101.46" fill="#2d3359" stroke="#57f2c6" stroke-miterlimit="10" stroke-width="4"></polygon>
                <polygon points="2551.76 1162.12 2551.76 1283.46 2656.84 1344.13 2656.84 1222.79 2551.76 1162.12" fill="#2d3359" stroke="#57f2c6" stroke-miterlimit="10" stroke-width="4"></polygon>
                <polygon points="2656.84 1344.13 2761.92 1283.46 2761.92 1162.12 2656.84 1222.79 2656.84 1344.13" fill="#2d3359" stroke="#57f2c6" stroke-miterlimit="10" stroke-width="4"></polygon>
              </g>
              <g id="Cube-184" data-name="Cube">
                <polygon points="2551.84 1162.46 2446.76 1223.12 2551.84 1283.79 2656.92 1223.12 2551.84 1162.46" fill="#2d3359" stroke="#57f2c6" stroke-miterlimit="10" stroke-width="4"></polygon>
                <polygon points="2446.76 1223.12 2446.76 1344.46 2551.84 1405.13 2551.84 1283.79 2446.76 1223.12" fill="#2d3359" stroke="#57f2c6" stroke-miterlimit="10" stroke-width="4"></polygon>
                <polygon points="2551.84 1405.13 2656.92 1344.46 2656.92 1223.12 2551.84 1283.79 2551.84 1405.13" fill="#2d3359" stroke="#57f2c6" stroke-miterlimit="10" stroke-width="4"></polygon>
              </g>
              <g id="Cube-185" data-name="Cube">
                <polygon points="2446.84 1223.46 2341.76 1284.12 2446.84 1344.79 2551.92 1284.12 2446.84 1223.46" fill="#2d3359" stroke="#57f2c6" stroke-miterlimit="10" stroke-width="4"></polygon>
                <polygon points="2341.76 1284.12 2341.76 1405.46 2446.84 1466.13 2446.84 1344.79 2341.76 1284.12" fill="#2d3359" stroke="#57f2c6" stroke-miterlimit="10" stroke-width="4"></polygon>
                <polygon points="2446.84 1466.13 2551.92 1405.46 2551.92 1284.12 2446.84 1344.79 2446.84 1466.13" fill="#2d3359" stroke="#57f2c6" stroke-miterlimit="10" stroke-width="4"></polygon>
              </g>
              <g id="Cube-186" data-name="Cube">
                <polygon points="2341.84 1284.46 2236.76 1345.12 2341.84 1405.79 2446.92 1345.12 2341.84 1284.46" fill="#2d3359" stroke="#57f2c6" stroke-miterlimit="10" stroke-width="4"></polygon>
                <polygon points="2236.76 1345.12 2236.76 1466.46 2341.84 1527.13 2341.84 1405.79 2236.76 1345.12" fill="#2d3359" stroke="#57f2c6" stroke-miterlimit="10" stroke-width="4"></polygon>
                <polygon points="2341.84 1527.13 2446.92 1466.46 2446.92 1345.12 2341.84 1405.79 2341.84 1527.13" fill="#2d3359" stroke="#57f2c6" stroke-miterlimit="10" stroke-width="4"></polygon>
              </g>
              <g id="Cube-187" data-name="Cube">
                <polygon points="2236.84 1345.46 2131.76 1406.12 2236.84 1466.79 2341.92 1406.12 2236.84 1345.46" fill="#2d3359" stroke="#57f2c6" stroke-miterlimit="10" stroke-width="4"></polygon>
                <polygon points="2131.76 1406.12 2131.76 1527.46 2236.84 1588.13 2236.84 1466.79 2131.76 1406.12" fill="#2d3359" stroke="#57f2c6" stroke-miterlimit="10" stroke-width="4"></polygon>
                <polygon points="2236.84 1588.13 2341.92 1527.46 2341.92 1406.12 2236.84 1466.79 2236.84 1588.13" fill="#2d3359" stroke="#57f2c6" stroke-miterlimit="10" stroke-width="4"></polygon>
              </g>
              <g id="Cube-188" data-name="Cube">
                <polygon points="2131.84 1406.46 2026.76 1467.12 2131.84 1527.79 2236.92 1467.12 2131.84 1406.46" fill="#2d3359" stroke="#57f2c6" stroke-miterlimit="10" stroke-width="4"></polygon>
                <polygon points="2026.76 1467.12 2026.76 1588.46 2131.84 1649.13 2131.84 1527.79 2026.76 1467.12" fill="#2d3359" stroke="#57f2c6" stroke-miterlimit="10" stroke-width="4"></polygon>
                <polygon points="2131.84 1649.13 2236.92 1588.46 2236.92 1467.12 2131.84 1527.79 2131.84 1649.13" fill="#2d3359" stroke="#57f2c6" stroke-miterlimit="10" stroke-width="4"></polygon>
              </g>
              <g id="Cube-189" data-name="Cube">
                <polygon points="2026.84 1467.46 1921.76 1528.12 2026.84 1588.79 2131.92 1528.12 2026.84 1467.46" fill="#2d3359" stroke="#57f2c6" stroke-miterlimit="10" stroke-width="4"></polygon>
                <polygon points="1921.76 1528.12 1921.76 1649.46 2026.84 1710.13 2026.84 1588.79 1921.76 1528.12" fill="#2d3359" stroke="#57f2c6" stroke-miterlimit="10" stroke-width="4"></polygon>
                <polygon points="2026.84 1710.13 2131.92 1649.46 2131.92 1528.12 2026.84 1588.79 2026.84 1710.13" fill="#2d3359" stroke="#57f2c6" stroke-miterlimit="10" stroke-width="4"></polygon>
              </g>
              <g id="Cube-190" data-name="Cube">
                <polygon points="1921.84 1528.46 1816.76 1589.12 1921.84 1649.79 2026.92 1589.12 1921.84 1528.46" fill="#2d3359" stroke="#57f2c6" stroke-miterlimit="10" stroke-width="4"></polygon>
                <polygon points="1816.76 1589.12 1816.76 1710.46 1921.84 1771.13 1921.84 1649.79 1816.76 1589.12" fill="#2d3359" stroke="#57f2c6" stroke-miterlimit="10" stroke-width="4"></polygon>
                <polygon points="1921.84 1771.13 2026.92 1710.46 2026.92 1589.12 1921.84 1649.79 1921.84 1771.13" fill="#2d3359" stroke="#57f2c6" stroke-miterlimit="10" stroke-width="4"></polygon>
              </g>
              <g id="Cube-191" data-name="Cube">
                <polygon points="1816.84 1589.46 1711.76 1650.12 1816.84 1710.79 1921.92 1650.12 1816.84 1589.46" fill="#2d3359" stroke="#57f2c6" stroke-miterlimit="10" stroke-width="4"></polygon>
                <polygon points="1711.76 1650.12 1711.76 1771.46 1816.84 1832.13 1816.84 1710.79 1711.76 1650.12" fill="#2d3359" stroke="#57f2c6" stroke-miterlimit="10" stroke-width="4"></polygon>
                <polygon points="1816.84 1832.13 1921.92 1771.46 1921.92 1650.12 1816.84 1710.79 1816.84 1832.13" fill="#2d3359" stroke="#57f2c6" stroke-miterlimit="10" stroke-width="4"></polygon>
              </g>
              <g id="Cube-192" data-name="Cube">
                <polygon points="1711.84 1650.46 1606.76 1711.12 1711.84 1771.79 1816.92 1711.12 1711.84 1650.46" fill="#2d3359" stroke="#57f2c6" stroke-miterlimit="10" stroke-width="4"></polygon>
                <polygon points="1606.76 1711.12 1606.76 1832.46 1711.84 1893.13 1711.84 1771.79 1606.76 1711.12" fill="#2d3359" stroke="#57f2c6" stroke-miterlimit="10" stroke-width="4"></polygon>
                <polygon points="1711.84 1893.13 1816.92 1832.46 1816.92 1711.12 1711.84 1771.79 1711.84 1893.13" fill="#2d3359" stroke="#57f2c6" stroke-miterlimit="10" stroke-width="4"></polygon>
              </g>
            </g>
          </g>
        </svg>


        {/* <svg
          className="BgAnimation__svg"
          viewBox="0 0 602 602"
          fill="none"
          xmlns="http://www.w3.org/2000/svg">
          <g opacity="0.15">
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M201.337 87.437C193.474 79.5738 180.725 79.5738 172.862 87.437L87.437 172.862C79.5739 180.725 79.5739 193.474 87.437 201.337L400.663 514.563C408.526 522.426 421.275 522.426 429.138 514.563L514.563 429.138C522.426 421.275 522.426 408.526 514.563 400.663L201.337 87.437ZM30.4869 115.912C-8.82897 155.228 -8.82897 218.972 30.4869 258.287L343.713 571.513C383.028 610.829 446.772 610.829 486.088 571.513L571.513 486.088C610.829 446.772 610.829 383.028 571.513 343.713L258.287 30.4869C218.972 -8.82896 155.228 -8.82896 115.912 30.4869L30.4869 115.912Z"
              stroke="url(#paint0_radial)"
              id="path_0"
            />
            <path
              d="M514.563 201.337C522.426 193.474 522.426 180.725 514.563 172.862L429.138 87.437C421.275 79.5738 408.526 79.5739 400.663 87.437L358.098 130.002L301.148 73.0516L343.713 30.4869C383.028 -8.82896 446.772 -8.82896 486.088 30.4869L571.513 115.912C610.829 155.228 610.829 218.972 571.513 258.287L357.802 471.999L300.852 415.049L514.563 201.337Z"
              stroke="url(#paint1_radial)"
              id="path_1"
            />
            <path
              d="M243.901 471.999L201.337 514.563C193.474 522.426 180.725 522.426 172.862 514.563L87.437 429.138C79.5739 421.275 79.5739 408.526 87.437 400.663L301.148 186.952L244.198 130.002L30.4869 343.713C-8.82897 383.028 -8.82897 446.772 30.4869 486.088L115.912 571.513C155.228 610.829 218.972 610.829 258.287 571.513L300.852 528.949L243.901 471.999Z"
              stroke="url(#paint2_radial)"
              id="path_2"
            />
          </g>
          <ellipse
            cx="295.027"
            cy="193.118"
            transform="translate(-295.027 -193.118)"
            rx="1.07306"
            ry="1.07433"
            fill="#945DD6"
          >
            <animateMotion dur="10s" repeatCount="indefinite" rotate="auto">
              <mpath xlinkHref="#path_2" />
            </animateMotion>
          </ellipse>
          <path
            d="M294.685 193.474L268.932 219.258"
            transform="translate(-294.685 -193.474) rotate(45 294.685 193.474)"
            stroke="url(#paint3_linear)"
          >
            <animateMotion dur="10s" repeatCount="indefinite" rotate="auto">
              <mpath xlinkHref="#path_2" />
            </animateMotion>
          </path>
          <ellipse
            cx="295.027"
            cy="193.118"
            transform="translate(-295.027 -193.118)"
            rx="1.07306"
            ry="1.07433"
            fill="#46737"
          >
            <animateMotion
              dur="5s"
              begin="1"
              repeatCount="indefinite"
              rotate="auto"
            >
              <mpath xlinkHref="#path_2" />
            </animateMotion>
          </ellipse>
          <path
            d="M294.685 193.474L268.932 219.258"
            transform="translate(-294.685 -193.474) rotate(45 294.685 193.474)"
            stroke="url(#paint7_linear)"
          >
            <animateMotion
              dur="5s"
              begin="1"
              repeatCount="indefinite"
              rotate="auto"
            >
              <mpath xlinkHref="#path_2" />
            </animateMotion>
          </path>
          <ellipse
            cx="476.525"
            cy="363.313"
            rx="1.07433"
            ry="1.07306"
            transform="translate(-476.525 -363.313) rotate(90 476.525 363.313)"
            fill="#945DD6"
          >
            <animateMotion dur="10s" repeatCount="indefinite" rotate="auto">
              <mpath xlinkHref="#path_0" />
            </animateMotion>
          </ellipse>
          <path
            d="M476.171 362.952L450.417 337.168"
            transform="translate(-476.525 -363.313) rotate(-45 476.171 362.952)"
            stroke="url(#paint4_linear)"
          >
            <animateMotion dur="10s" repeatCount="indefinite" rotate="auto">
              <mpath xlinkHref="#path_0" />
            </animateMotion>
          </path>
          <ellipse
            cx="382.164"
            cy="155.029"
            rx="1.07433"
            ry="1.07306"
            transform="translate(-382.164 -155.029) rotate(90 382.164 155.029)"
            fill="#F46737"
          >
            <animateMotion
              dur="10s"
              begin="1"
              repeatCount="indefinite"
              rotate="auto"
            >
              <mpath xlinkHref="#path_0" />
            </animateMotion>
          </ellipse>
          <path
            d="M381.81 154.669L356.057 128.885"
            transform="translate(-381.81 -154.669) rotate(-45 381.81 154.669)"
            stroke="url(#paint5_linear)"
          >
            <animateMotion
              dur="10s"
              begin="1"
              repeatCount="indefinite"
              rotate="auto"
            >
              <mpath xlinkHref="#path_0" />
            </animateMotion>
          </path>
          <ellipse
            cx="333.324"
            cy="382.691"
            rx="1.07306"
            ry="1.07433"
            transform="translate(-333.324 -382.691) rotate(-180 333.324 382.691)"
            fill="#F46737"
          >
            <animateMotion
              dur="5s"
              begin="0"
              repeatCount="indefinite"
              rotate="auto"
            >
              <mpath xlinkHref="#path_1" />
            </animateMotion>
          </ellipse>
          <path
            d="M333.667 382.335L359.42 356.551"
            transform="scale(-1 1) translate(-333.667 -382.335) rotate(45 333.667 382.335)"
            stroke="url(#paint6_linear)"
          >
            <animateMotion
              dur="5s"
              begin="0"
              repeatCount="indefinite"
              rotate="auto"
            >
              <mpath xlinkHref="#path_1" />
            </animateMotion>
          </path>
          <ellipse
            cx="165.524"
            cy="93.9596"
            rx="1.07306"
            ry="1.07433"
            transform="translate(-165.524 -93.9596)"
            fill="#F46737"
          >
            <animateMotion
              dur="10s"
              begin="3"
              repeatCount="indefinite"
              rotate="auto"
            >
              <mpath xlinkHref="#path_0" />
            </animateMotion>
          </ellipse>
          <path
            d="M165.182 94.3159L139.429 120.1"
            transform="translate(-165.182 -94.3159) rotate(45 165.182 94.3159)"
            stroke="url(#paint7_linear)"
          >
            <animateMotion
              dur="10s"
              begin="3"
              repeatCount="indefinite"
              rotate="auto"
            >
              <mpath xlinkHref="#path_0" />
            </animateMotion>
          </path>
          <ellipse
            cx="476.525"
            cy="363.313"
            rx="1.07433"
            ry="1.07306"
            transform="translate(-476.525 -363.313) rotate(90 476.525 363.313)"
            fill="#13ADC7"
          >
            <animateMotion
              dur="12s"
              begin="4"
              repeatCount="indefinite"
              rotate="auto"
            >
              <mpath xlinkHref="#path_0" />
            </animateMotion>
          </ellipse>
          <path
            d="M476.171 362.952L450.417 337.168"
            transform="translate(-476.525 -363.313) rotate(-45 476.171 362.952)"
            stroke="url(#paint11_linear)"
          >
            <animateMotion
              dur="12s"
              begin="4"
              repeatCount="indefinite"
              rotate="auto"
            >
              <mpath xlinkHref="#path_0" />
            </animateMotion>
          </path>
          <defs>
            <radialGradient
              id="paint0_radial"
              cx="0"
              cy="0"
              r="1"
              gradientUnits="userSpaceOnUse"
              gradientTransform="translate(301 301) rotate(90) scale(300)"
            >
              <stop offset="0.333333" stopColor="#FBFBFB" />
              <stop offset="1" stopColor="white" stopOpacity="0" />
            </radialGradient>
            <radialGradient
              id="paint1_radial"
              cx="0"
              cy="0"
              r="1"
              gradientUnits="userSpaceOnUse"
              gradientTransform="translate(301 301) rotate(90) scale(300)"
            >
              <stop offset="0.333333" stopColor="#FBFBFB" />
              <stop offset="1" stopColor="white" stopOpacity="0" />
            </radialGradient>
            <radialGradient
              id="paint2_radial"
              cx="0"
              cy="0"
              r="1"
              gradientUnits="userSpaceOnUse"
              gradientTransform="translate(301 301) rotate(90) scale(300)"
            >
              <stop offset="0.333333" stopColor="#FBFBFB" />
              <stop offset="1" stopColor="white" stopOpacity="0" />
            </radialGradient>
            <linearGradient
              id="paint3_linear"
              x1="295.043"
              y1="193.116"
              x2="269.975"
              y2="218.154"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="#945DD6" />
              <stop offset="1" stopColor="#945DD6" stopOpacity="0" />
            </linearGradient>
            <linearGradient
              id="paint4_linear"
              x1="476.529"
              y1="363.31"
              x2="451.461"
              y2="338.272"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="#945DD6" />
              <stop offset="1" stopColor="#945DD6" stopOpacity="0" />
            </linearGradient>
            <linearGradient
              id="paint5_linear"
              x1="382.168"
              y1="155.027"
              x2="357.1"
              y2="129.989"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="#F46737" />
              <stop offset="1" stopColor="#F46737" stopOpacity="0" />
            </linearGradient>
            <linearGradient
              id="paint6_linear"
              x1="333.309"
              y1="382.693"
              x2="358.376"
              y2="357.655"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="#F46737" />
              <stop offset="1" stopColor="#F46737" stopOpacity="0" />
            </linearGradient>
            <linearGradient
              id="paint7_linear"
              x1="165.54"
              y1="93.9578"
              x2="140.472"
              y2="118.996"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="#F46737" />
              <stop offset="1" stopColor="#F46737" stopOpacity="0" />
            </linearGradient>
            <linearGradient
              id="paint8_linear"
              x1="414.367"
              y1="301.156"
              x2="439.435"
              y2="276.118"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="#13ADC7" />
              <stop offset="1" stopColor="#13ADC7" stopOpacity="0" />
            </linearGradient>
            <linearGradient
              id="paint9_linear"
              x1="515.943"
              y1="288.238"
              x2="541.339"
              y2="291.454"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="#13ADC7" />
              <stop offset="1" stopColor="#13ADC7" stopOpacity="0" />
            </linearGradient>
            <linearGradient
              id="paint10_linear"
              x1="117.001"
              y1="230.619"
              x2="117.36"
              y2="258.193"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="#945DD6" />
              <stop offset="1" stopColor="#945DD6" stopOpacity="0" />
            </linearGradient>
            <linearGradient
              id="paint11_linear"
              x1="476.529"
              y1="363.31"
              x2="451.461"
              y2="338.272"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="#13ADC7" />
              <stop offset="1" stopColor="#13ADC7" stopOpacity="0" />
            </linearGradient>
          </defs>
        </svg> */}
      </div>
    );
  }
}
export default BackgroundAnimation;
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Axios from 'axios';

const Projects = () => {

    //These are the variables that are set to pass the projects data
    const [projects, setProjects] = useState([]);

    // This is to navigate on a specific project based on the id it has
    const navigate = useNavigate();

    function handleClick(id) {
        navigate("/FullProject/" + id);
    }


    function getProjectInformations() {
        Axios.defaults.withCredentials = true;

        Axios.get(`${process.env.REACT_APP_API}/api/project`)
            .then((response) => {
                if (response.data) {
                    let projectList = [];

                    for (let i = 0; i < response.data.length; i++) {

                        let singleProject = {
                            projectId: response.data[i]._id,
                            title: response.data[i].title,
                            smallDescription: response.data[i].smalldescription,
                            smallImage: response.data[i].smallimage,
                            tags: response.data[i].tags
                        }

                        projectList.push(singleProject);
                    }

                    setProjects(projectList);
                }
                else {
                    alert("Can't get projects informations!");
                }
            })
            .catch((error) => {
                if (error.response) {
                    alert("The server or client crashed to get the projects informations");
                }
                else if (error.request) {
                    alert("Problem with the request");
                }
            });
    }

    useEffect(() => {
        getProjectInformations();
        // eslint-disable-next-line react-hooks/exhaustive-deps 
    }, []);


    return (
        <section id="projects">

            <div id="projectsSectionDivider"/>

            <h2 id='projectsSectionTitle' main>Projects</h2>
            
            <section id='gridContainerProject'>
                {projects.map((p, i) => {
                    return (
                        <div id='blogCardProject' key={i}>
                            <img id='imgProject' src={`/images/${p.smallImage}`} />
                            <div id='titleContentProject'>
                                <h3 id='headerThreeProject' title>{p.title}</h3>
                            </div>
                            <div>

                                <ul id='tagListProject'>
                                    {p.tags.map((t, i) => {
                                        return <li id='tagProject' key={i}>{t}</li>;
                                    })}
                                </ul>
                            </div>

                            <ul id='utilityListProject'>
                                <a id='externalLinksProject' onClick={() => handleClick(p.projectId)}>Learn more</a>
                            </ul>
                        </div>
                    );
                })}
            </section>
        </section>
    );
};

export default Projects;
import React, { useState, useEffect } from 'react';
import Axios from 'axios';
import * as Symbol from 'react-icons/di';

const Technologies = () =>  {

  // Variables set for technologies

  const [technologies, setTechnologies] = useState([]);

  // This will display the biography informations and update the information in the entire website, 

  function getTechnologiesInformations() {

    Axios.defaults.withCredentials = true;

    Axios.get(`${process.env.REACT_APP_API}/api/technology`)
        .then((response) => {
          if (response.data) {

            let technologyList = [];
            
            for (let i = 0; i < response.data.length; i++){
                        
              let singleTechnology = {
                  technologyId: response.data[i]._id,
                  iconsList: response.data[i].iconslist,
                  listTitle: response.data[i].listtitle,
                  listParagraph: response.data[i].listParagraph,
              }

              technologyList.push(singleTechnology);
            }
            setTechnologies(technologyList)
          }
          else{
              alert("Can't get the technologies informations!");
          }
        })
        .catch((error) => {
            if (error.response) {
              alert("The server or client crashed to get the technologies informations");
            }
            else if (error.request) {
              alert("Problem with the request");
            }
        });
  }
  
  useEffect(() => {
    getTechnologiesInformations();
      // eslint-disable-next-line react-hooks/exhaustive-deps 
  }, []);




  return (
    <section id="tech">

      <div class='technologyDivider' divider />

      <h2 id='technologyTitle'>Technologies</h2>

      <p id='technologyText'>
        I've worked with a range a technologies in the programming world.
        From designing user-interfaces to modeling databases with business logic
      </p>

      <ul id='listTechnologies'>
        {technologies.map((t) => {
          return(
            <li id='listItemTechnologies'>
              <picture>
                {t.iconsList.map((w) => {
                  const MyComponent = Symbol[w];
                  return <MyComponent size="4rem" />
                })}
              </picture>

              <div id='listContainerTechnologies'>
                <h4 id='listTitleTechnologies'>{t.listTitle}</h4>
                <p id='listParagraphTechnologies'>
                  {t.listParagraph}
                </p>
              </div>
            </li>
          );
        })}
      </ul>

      <div class='technologyDivider' colorAlt/>
    </section>
  );
}
export default Technologies;
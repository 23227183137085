import React, { useState, useEffect } from 'react';
import emailjs from 'emailjs-com';
import ReCAPTCHA from "react-google-recaptcha";
import Axios from 'axios';

import { AiFillGithub, AiFillLinkedin } from 'react-icons/ai';

const Contact = () => {

  // SECTION FOR SENDING EMAIL MESSAGE

  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [changeCaptcha, setChangeCaptcha] = useState("");

  function sendEmail(e) {

    e.preventDefault();
    setSuccessMessage("");
    setErrorMessage("");

    if (!e.target.email.value) {
      setErrorMessage("You must enter your email address.");
      return;
    }
    if (!e.target.subject.value) {
      setErrorMessage("You must enter a subject to the email.");
      return;
    }
    if (!e.target.message.value) {
      setErrorMessage("You must enter a message to the email.");
      return;
    }
    if (!changeCaptcha) {
      setErrorMessage("Are you a robot?");
      return;
    }


    var templateParams = {
      email: e.target.email.value,
      subject: e.target.subject.value,
      message: e.target.message.value,
      'g-recaptcha-response': changeCaptcha
    }



    emailjs.send(process.env.REACT_APP_EMAIL_SERVICE, process.env.REACT_APP_EMAIL_TEMPLATE, templateParams, process.env.REACT_APP_EMAIL_PUBLIC_KEY)
      .then(() => {
        setSuccessMessage("Email sent successfully!");
        setChangeCaptcha("");
      },
        (error) => {
          console.log(error.text);
        });

    e.target.reset();
  }

  // These are the variables that are set to pass the contact data in the entire applications
  const [contactId, setContactId] = useState("");
  const [telephone, setTelephone] = useState("");
  const [email, setEmail] = useState("");
  const [slogan, setSlogan] = useState("");


  function getContactInformation() {
    Axios.defaults.withCredentials = true;

    Axios.get(`${process.env.REACT_APP_API}/api/contact`)
      .then((response) => {
        if (response.data) {
          setContactId(response.data[0]._id);
          setTelephone(response.data[0].telephone);
          setEmail(response.data[0].email);
          setSlogan(response.data[0].slogan);
        }
        else {
          alert("Can't get the contact informations!");
        }
      })
      .catch((error) => {
        if (error.response) {
          alert("The server or client crashed to get the contact informations");
        }
        else if (error.request) {
          alert("Problem with the request");
        }
      });
  }

  useEffect(() => {
    getContactInformation();
    // eslint-disable-next-line react-hooks/exhaustive-deps 
  }, []);

  return (
    <section id="contact">

      <h2 id='sectionTitleContact' main>Contact</h2>

      <h2 id='titleContact' main>Say Hi!</h2>
      <div className='container'>
        <form onSubmit={sendEmail}>
          <p id='successMessageContact'>{successMessage}</p>
          <p id='errorMessageContact'>{errorMessage}</p>

          <input type="email" className='inputContact form-control' placeholder='Enter your email address' name='email' />
          <input type="text" className='inputContact form-control' placeholder='Subject of the email' name='subject' />
          <textarea type="text" className='messageInputContact form-control' placeholder='Message of the email' name='message' />


          <div id='recaptchaStyleContact'>
            <ReCAPTCHA sitekey={process.env.REACT_APP_SITE_KEY} onChange={setChangeCaptcha} />
          </div>
          <br />

          <div>
            <input type="submit" id='submitButtonContact' className='btn btn-info' value="Send Email" />
          </div>
        </form>
      </div>


      <ul id='linkListContact'>

        <div className='linkColumnContact'>
          <h4 className='linkTitleContact'>Telephone</h4>
          <a className='linkItemContact' href={`tel:${telephone}`}>{telephone}</a>
        </div>

        <div className='linkColumnContact'>
          <h4 className='linkTitleContact'>Email</h4>
          <a className='linkItemContact' href={`mailto:${email}`}>{email}</a>
        </div>
      </ul>


      <div id='socialIconsContainerContact'>
        <div>
          <p id='sloganContact'>{slogan}</p>
        </div>

        <div id='socialContainerContact'>
          <a id='socialIconsGitHubContact' href="https://github.com/NickEspitalier">
            <AiFillGithub size="3rem" />
          </a>

          <a id='socialIconsLinkedinContact' href="https://www.linkedin.com/in/nicolas-espitalier-13b960199/">
            <AiFillLinkedin size="3rem" />
          </a>
        </div>
      </div>

      <div id='licenseArea'>
        <p id='license'>© 2024 All rights reserved Nicolas Espitalier</p>
      </div>
    </section>
  );
};

export default Contact;
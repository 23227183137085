import React, { useState } from 'react'
import BgAnimation from './components/BackgroundAnimation/BackgroundAnimation';
import Header from './components/Header/Header';
import Video from './components/Video/Video';
import Biography from './components/Biography/Biography';
import Projects from './components/Projects/Projects';
import Technologies from './components/Technologies/Technologies';
import Contact from './components/Contact/Contact';


const Home = () => {

  //This is to display or not the buttons
  const [buttonActivated, setButtonActivated] = useState("");

  const changeButtonsState = (buttonState) => {
    setButtonActivated(buttonState);
  }

  //This is to pass the biography informations in the entire application

  const [fullName, setFullName] = useState("");

  const changeFullName = (fullname) => {
    setFullName(fullname)
  }


  return (
    <div id='containerHome'>
      <Header changeButtonsInHome={changeButtonsState} />
      <Video name={fullName} />

      <div id='smallContainerHome'>
        <section id="sectionHome" grid>
          <Biography getButtonState={buttonActivated} getFullName={changeFullName} />
          <BgAnimation />
        </section>
        <Projects getButtonState={buttonActivated} />
        <Technologies getButtonState={buttonActivated} />
        <Contact getButtonState={buttonActivated} />
      </div>
      <div id='portal'></div>
    </div>
  );
};

export default Home;
import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import App from './App';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../src/styles/GlobalStyles.css'
import '../src/styles/VideoStyles.css';
import '../src/styles/animationStyles.css';
import '../src/styles/HomeStyles.css';
import '../src/styles/HeaderStyles.css';
import '../src/styles/BiographyStyles.css';
import '../src/styles/ProjectsStyles.css';
import '../src/styles/TechnologiesStyles.css';
import '../src/styles/ContactStyles.css';
import '../src/styles/FullBiographyStyles.css';
import '../src/styles/FullProjectStyles.css';


ReactDOM.render(
    
        <BrowserRouter>
            <App />
        </BrowserRouter>, 
document.getElementById('root'));